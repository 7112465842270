import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ThemeInterface } from '../style/theme';
import { DesignPhase } from '../constants/DesignPhases';
import { get } from 'lodash';
import { withTheme } from 'styled-components';
interface DesignPhaseIconProps extends SvgIconProps {
  withColor?: boolean | string;
  designPhase: DesignPhase;
  theme: ThemeInterface;
}

export default withTheme(
  ({ withColor, designPhase, theme, ...otherProps }: DesignPhaseIconProps) => (
    <SvgIcon
      {...otherProps}
      viewBox={`0 0 32 32`}
      width={32}
      height={32}
      htmlColor={
        typeof withColor === 'string'
          ? withColor
          : withColor === true
          ? get(theme.designPhaseColors, designPhase)
          : undefined
      }
    >
      {(function() {
        switch (designPhase) {
          case 'challenge':
            return (
              <path
                id="a"
                d="M12.081 9.104c-.823 0-1.607.262-2.094 1.047-.762 1.23-1.213 4.274-.625 5.482 1.308 2.689 5.752 5.944 7.125 6.903 1.008-1.221 4.143-5.078 5.458-7.394.435-.767.85-1.597.941-2.451.09-.854-.203-1.67-.743-2.086l-.11-.11c-1.838-2.525-5.959-.386-5.997-.362l-.27.14-.263-.15c-.028-.016-1.805-1.02-3.422-1.02zM16.692 24l-.41-.271c-.252-.17-6.233-4.176-7.91-7.617-.763-1.57-.262-5.013.68-6.536 1.759-2.836 5.68-1.086 6.736-.544 1.07-.498 4.973-2.043 7.09.756.804.66 1.227 1.806 1.1 3.017-.11 1.053-.583 2.007-1.075 2.877-1.622 2.853-5.72 7.737-5.895 7.942l-.316.376z"
              />
            );
          case 'ideas':
            return (
              <path
                id="a"
                d="M12.648 27v-1.162h7.056V27h-7.056zm3.406-17.523l1.186-.232 1.339 6.354-3.307-2.212.572 2.163-1.173.287-1.374-5.184 3.497 2.337-.74-3.513zm-3.178 14.03v-1.161h6.828v1.161h-6.828zm9.498-8.491c1.165-2.898-.175-6.514-2.991-8.057-.999-.546-2.138-.808-3.279-.808-2.078 0-4.17.863-5.467 2.437-1.208 1.465-1.695 3.463-1.275 5.223.813 3.39 3.808 5.087 6.42 5.267 2.35.18 5.29-.821 6.592-4.062zm-2.39-9.066c3.313 1.816 4.894 6.072 3.52 9.485v.001c-1.214 3.016-3.936 4.823-7.194 4.823-.202 0-.408-.006-.613-.021-3.057-.21-6.565-2.199-7.513-6.167-.51-2.125.053-4.445 1.504-6.203 2.363-2.87 6.98-3.731 10.296-1.918z"
              />
            );
          case 'presentation':
            return (
              <path
                id="a"
                d="M17.041 9.184a6 6 0 0 0-.422.014c-2.967.2-5.802 2.394-6.89 5.328-.76 2.049-.628 4.23.357 5.839 1.423 2.318 4.409 3.277 6.727 3.402 1.819.1 5.262.04 6.744.008l-.79-4.891.023-.103c.007-.032.68-3.248.466-4.793-.37-2.642-3.217-4.804-6.215-4.804M19.671 25c-1.079 0-2.14-.014-2.929-.056-3.42-.182-6.31-1.673-7.73-3.986-1.163-1.904-1.335-4.454-.457-6.823 1.253-3.365 4.529-5.88 7.974-6.112 3.853-.302 7.48 2.398 7.959 5.813.22 1.59-.339 4.48-.458 5.072l.97 6.01-.705.02c-.14.002-2.418.062-4.624.062"
              />
            );
          case 'prototype':
            return (
              <path
                id="a"
                d="M17.387 16.958c-.343.182-.73.227-1.037.103l-.153.368c-.518-.355-.81-1.011-.697-1.58.117-.591.694-1.08 1.281-1.091h.016c.33 0 .673.167.923.452.249.284.363.639.303.955-.058.314-.297.611-.636.793m-.627-3.302c.706.02 1.352.295 1.833.84.477.543.68 1.223.556 1.866-.118.643-.56 1.213-1.207 1.563-.648.345-1.384.401-2.018.161l.152-.366-.451.67c-.939-.593-1.454-1.723-1.25-2.75.222-1.11 1.248-1.964 2.385-1.984zm6.416 9.391l-2.188-3.16 1.326-3.831 3.092-.994c-.343-.694-.912-1.857-1.217-2.528l-2.839.175-.187-.221c-.937-1.114-2.766-4.375-3.325-5.384h-1.533l-1.08 3.115-3.833 2.466-3.16-1.017-.782 2.267 3.163 1.017.09.26s.835 2.379 1.15 3.32l.105.32-.246.239c-.802.774-1.63 2.17-1.992 2.82l1.566 1.513c.582-.567 1.729-1.501 3.302-1.94l.324-.091.239.23c.938.91 2.785.961 3.387.638l.659-.348.171.707c.178.719 1.294 1.55 2.15 2.025l1.658-1.598zm.04-6.12l-.964 2.797 2.396 3.464L21.734 26l-.369-.178c-.242-.117-2.153-1.07-2.883-2.381-1.072.245-2.742.074-3.873-.807-1.762.599-2.84 1.916-2.851 1.93l-.397.494-3.04-2.935.185-.355c.044-.087 1.049-2.012 2.151-3.207-.28-.825-.75-2.173-.946-2.736L6 14.632l1.51-4.361 3.712 1.195 3.044-1.956L15.48 6h3.05l.16.294c.023.042 2.1 3.84 3.186 5.276l3.077-.19.146.387c.191.496 1.234 2.61 1.62 3.382l.281.565-3.784 1.213z"
              />
            );
          case 'reflection':
            return (
              <path
                id="a"
                d="M12.051 20.164c.512 0 .986.078 1.39.314.73.422 1.105 1.104 1.434 1.705.463.84.828 1.504 1.996 1.695 1.727.287 3.63-.917 4.149-2.62l.216-.712.597.43c.937.68 2.26.785 3.294.272 1.032-.515 1.758-1.648 1.807-2.818.026-.6-.108-1.206-.25-1.846-.103-.462-.205-.924-.251-1.39a12.28 12.28 0 0 1-.045-1.258c-.002-.647-.002-1.262-.172-1.822-.241-.8-.924-1.463-1.829-1.772-.9-.306-1.84-.198-2.505.291l-.5.37-.282-.56c-.846-1.656-2.836-2.636-4.644-2.29-1.803.356-3.294 2.023-3.468 3.88l-.075.8-.7-.37c-1.149-.613-2.658-.457-3.662.378-.954.791-1.409 2.152-1.15 3.37l.374.22-.522.94c-.265-.15-.536-.001-.676.104-.365.273-.577.766-.499 1.148.233 1.115 1.612 1.604 2.026 1.73.657.189 1.537.073 2.388-.044.542-.074 1.07-.145 1.56-.145M17.394 25c-.231 0-.462-.018-.69-.056-1.678-.271-2.275-1.36-2.756-2.232-.3-.548-.562-1.02-1.036-1.297-.48-.28-1.395-.155-2.28-.036-.962.134-1.95.269-2.824.01-1.496-.443-2.53-1.394-2.77-2.542-.166-.795.208-1.717.909-2.24.128-.1.264-.177.402-.237-.308-1.586.293-3.34 1.53-4.366 1.136-.949 2.773-1.233 4.168-.778.486-2.02 2.19-3.736 4.208-4.134 2.085-.408 4.324.57 5.502 2.362.873-.427 1.946-.483 2.967-.136 1.229.42 2.167 1.346 2.507 2.48.215.714.215 1.436.217 2.136 0 .384 0 .768.039 1.146.045.426.14.848.232 1.266.15.682.308 1.386.276 2.13-.066 1.579-1.009 3.048-2.4 3.741a4.29 4.29 0 0 1-3.807-.014C20.993 23.869 19.213 25 17.395 25"
              />
            );
          case 'research':
            return (
              <path
                id="a"
                d="M23.536 16.132c-1.13 2.52-2.974 4.263-5.06 4.783-1.8.45-3.684-.035-5.455-1.402-1.936-1.496-2.987-4.045-2.616-6.349.465-2.864 3.106-5.313 6.28-5.823a7.869 7.869 0 0 1 1.23-.094c2.229 0 4.296.963 5.38 2.58 1.132 1.697 1.225 4.11.241 6.305m.803-6.995c-1.55-2.313-4.71-3.526-7.852-3.024-3.694.59-6.772 3.473-7.319 6.854-.428 2.635.65 5.416 2.742 7.228L7 24.535l.834.931 5.102-4.51c1.366.892 2.791 1.373 4.212 1.373.545 0 1.092-.067 1.632-.204 2.464-.613 4.615-2.614 5.903-5.485 1.157-2.581 1.024-5.454-.344-7.503"
              />
            );
          default:
            return <></>;
        }
      })()}
    </SvgIcon>
  )
);
