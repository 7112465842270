import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { isBrowser, getScrollPage, scrollTo } from './utils';

interface RediaScrollMemoryProps extends RouteComponentProps {
  blackList?: string[];
}

export const RediaScrollMemory = withRouter((props: RediaScrollMemoryProps) => {
  const [prevLocation, setPrevLocation] = useState(props.location);
  const [windowPositionMap, setWindowPositionMap] = useState(new Map());
  let scroll = getScrollPage();
  useEffect(() => {
    window.addEventListener('popstate', detectPop);
    return () => window.removeEventListener('popstate', detectPop);
  }, []);

  useEffect(() => {
    const actual = prevLocation;
    const next = props.location;

    const blackListed = props.blackList
      ? props.blackList.some(element => {
          return next.pathname.indexOf(element) > -1;
        })
      : false;

    const locationChanged =
      (next.pathname !== actual.pathname || next.search !== actual.search) &&
      next.hash === '';

    if (locationChanged && !blackListed) {
      scrollTo(0);
      // save scroll with key location
      setWindowPositionMap(windowPositionMap.set(actual.key, scroll));
    }

    if (!blackListed) {
      setPrevLocation(props.location);
    }
  }, [props.location.pathname]);

  function detectPop(event: PopStateEvent) {
    if (!isBrowser()) return;
    const key = event.state ? event.state.key : 'enter';
    const pageScroll = windowPositionMap.get(key);
    if (pageScroll) {
      scrollTo(pageScroll);
    }
  }

  return null;
});
