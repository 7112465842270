import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box
} from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import theme from '../../style/theme';
import { CardProps } from '@material-ui/core/Card';
import styled from 'styled-components';

const borderRadius = 14;

const CardContentStyled = styled(({ contentHeight, ...otherProps }) => (
  <CardContent {...otherProps} />
))<{ contentHeight: number }>`
  display: flex;
  flex-direction: column;
  height: ${props => props.contentHeight}px;
  position: relative;
  && {
    padding-top: 26px;
  }
`;

export const CourseCardSubText = styled(Typography)`
  bottom: 14px;
`;

const SubtitleStyled = styled(Typography)`
  && {
    line-height: 1.5;
  }
`;

const CourseTitle = styled(Typography)`
  && {
    font-size: ${props => props.theme.courseTitle.fontSize}px;
    line-height: ${props => props.theme.courseTitle.lineHeight};
    font-weight: ${props => props.theme.courseTitle.fontWeight};
    color: ${props => props.theme.courseTitle.color};
    margin: 0px;
    padding-bottom: 2px;
  }
`;

const CardItem = styled(({ disabled, hoverEnabled, greyed, ...otherProps }) => (
  <Card {...otherProps} />
))<{ disabled?: boolean; hoverEnabled?: boolean }>`
  opacity: ${props => (props.disabled || props.greyed ? 0.4 : 1)};
  filter: ${props =>
    props.disabled || props.greyed ? `grayscale(100%)` : 'none'};
  width: 100%;

  &:hover {
    background-color: ${props =>
      props.hoverEnabled ? theme.extraPalette.sand[500] : 'none'};
  }

  pointer-events: ${props => (props.disabled ? `none` : 'auto')};

  &:active {
    background-color: ${props =>
      props.hoverEnabled ? theme.extraPalette.grey[500] : 'none'};
  }
`;

const Image = ({
  title,
  hover,
  image,
  height
}: {
  title: string;
  hover: boolean;
  image?: string;
  height: number;
}) => {
  return (
    <ImageStyled hover={hover} image={image} title={title} height={height} />
  );
};

const Content = ({ props }: { props: LargeCardProps }) => {
  const contentHeight = props.subLeft || props.subRight ? 155 : 135;
  return (
    <CardContentStyled contentHeight={contentHeight}>
      <Box flex={1}>
        <CourseTitle>{props.title}</CourseTitle>
      </Box>
      <Box flex={3}>
        <SubtitleStyled variant="subtitle1">
          {props.shortDescription}
        </SubtitleStyled>
      </Box>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flex={2}>
          {typeof props.subLeft === 'function' && props.subLeft()}
          {typeof props.subLeft === 'string' && (
            <CourseCardSubText variant="caption" color="secondary">
              {props.subLeft}
            </CourseCardSubText>
          )}
        </Box>
        {typeof props.subRight !== 'undefined' && (
          <Box flex={1} display="flex" justifyContent="flex-end">
            <>
              {typeof props.subRight === 'function' && props.subRight()}
              {typeof props.subRight === 'string' && (
                <CourseCardSubText variant="caption" color="secondary">
                  {props.subRight}
                </CourseCardSubText>
              )}
            </>
          </Box>
        )}
      </Box>
    </CardContentStyled>
  );
};

const HoverOverlay = styled.div<{
  height: number;
}>`
  && {
    width: 100%;
    min-height: ${props => props.height}px;
    border-radius: ${borderRadius - 4}px;
    background-color: ${props => props.theme.palette.secondary.main};
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

const ImageStyled = styled(({ hover, image, title, height, ...otherProps }) => (
  <CardMedia title={title} image={image} {...otherProps} />
))<{ hover: boolean; height: number }>`
  width: 100%;
  min-height: ${props => props.height}px;
  border-bottom-left-radius: ${borderRadius}px;
  border-bottom-right-radius: ${borderRadius}px;

  filter: ${props => (props.hover ? 'grayscale(100%)' : 'none')};

  box-shadow: ${props =>
    props.hover
      ? 'none'
      : `0 3px 3px -1px rgba(0, 0, 0, 0.27), 0 1px 11px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.1)`};
`;

const ButtonStyled = styled(Button)`
  && {
    position: absolute;
    z-index: 3;
    pointer-events: none;
  }
`;

const ImageHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

interface LargeCardProps extends CardProps {
  title?: string;
  children?: React.ReactNode;
  shortDescription?: string;
  imageLink?: string;
  onClick?: () => void;
  subLeft?: (() => React.ReactNode) | string;
  subRight?: (() => React.ReactNode) | string;
  imageOverlay?: (() => React.ReactNode) | string; // force update
  image?: string;
  imageHeight?: number;
  disabled?: boolean;
  buttonText?: string;
  renderContainer?: () => React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  renderImageButtons?: () => React.ReactNode;
  greyed?: boolean;
}

const ImageButtonsHolder = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 360px;
  height: ${props => props.height}px;
  width: 100%;
  z-index: 10;
`;

export default function LargeCard(props: LargeCardProps) {
  const [hover, setHover] = useState(false);
  const {
    disabled,
    imageLink,
    title,
    children,
    shortDescription,
    subLeft,
    subRight,
    imageOverlay,
    image,
    buttonText,
    renderContainer,
    renderImageButtons,
    style,
    className,
    onClick,
    ...otherProps
  } = props;

  const greyed = props.greyed ? props.greyed : false;
  const imageHeight = props.imageHeight ? props.imageHeight : 280;

  const imageHolder = (
    <>
      <ImageHolder>
        {typeof imageOverlay === 'function' && imageOverlay()}

        {hover && (
          <>
            {buttonText && (
              <ButtonStyled variant="contained" color="primary">
                {buttonText}
              </ButtonStyled>
            )}
            <HoverOverlay height={imageHeight} />
          </>
        )}
        {image && (
          <Image
            title={title ? title : ''}
            image={image}
            hover={hover}
            height={imageHeight}
          />
        )}
      </ImageHolder>

      <Content props={props} />
    </>
  );

  const hoverEnabled = typeof imageLink !== 'undefined';

  return (
    <div
      onMouseEnter={() => (!props.disabled ? setHover(true) : null)}
      onMouseLeave={() => setHover(false)}
      style={{ width: '100%', position: 'relative' }}
    >
      {renderImageButtons && hover && (
        <ImageButtonsHolder height={imageHeight}>
          {renderImageButtons()}
        </ImageButtonsHolder>
      )}
      <CardItem
        square={true}
        disabled={disabled}
        hoverEnabled={hoverEnabled}
        style={style}
        className={className}
        {...otherProps}
        elevation={hover ? 0 : 2}
        greyed={greyed}
      >
        {typeof renderContainer === 'function' ? (
          renderContainer()
        ) : typeof imageLink === 'undefined' &&
          typeof onClick === 'undefined' ? (
          <>
            <Image
              hover={false}
              title={title ? title : ''}
              image={image}
              height={imageHeight}
            />
            <Content props={props} />
          </>
        ) : (
          <>
            {typeof imageLink !== 'undefined' && (
              <Link to={imageLink} style={{ textDecoration: 'none' }}>
                {imageHolder}
              </Link>
            )}
            {typeof onClick !== 'undefined' && (
              <div style={{ cursor: 'pointer' }} onClick={onClick}>
                {imageHolder}
              </div>
            )}
          </>
        )}
        {children}
      </CardItem>
    </div>
  );
}
