import { firebaseapp } from '../../../../services/firebase';
import FirestoreBaseModel from '../../../FirestoreBase';

export const COURSES_COLLECTION = 'courses';
export const TEACHERS_GUIDE_COLLECTION = 'teachersGuide';

export interface FSCourseTeachersGuide extends FirestoreBaseModel {
  title: string;
  order: number;
  content: string;
}

export function getCourseTeachersGuideCollectionRef(courseId: string) {
  return firebaseapp
    .firestore()
    .collection(
      `${COURSES_COLLECTION}/${courseId}/${TEACHERS_GUIDE_COLLECTION}`
    );
}
