import { useState, useEffect } from 'react';
import { collectionData, docData } from 'rxfire/firestore';
import { concat } from 'rxjs/operators';
import {
  FSInstitution,
  getInstitutionsCollectionRef
} from '../../collections/Institutions';
import {
  FSUnicGroup,
  getInstitutionsUnicGroupsCollectionRef
} from '../../collections/Institutions/UnicGroups';
import { User } from '../../../components/Auth/AuthProvider';
import {
  getInstitutionsUsersCollectionRef,
  FSInstututionUser
} from '../../collections/Institutions/Users';

export type UnicGroupType = 'Hovedgruppe' | 'Hold';

export function useInstitutions() {
  const [institutions, setInstitutions] = useState<FSInstitution[] | undefined>(
    undefined
  );
  useEffect(() => {
    const subscription = collectionData<FSInstitution>(
      getInstitutionsCollectionRef(),
      'id'
    )
      .pipe(concat())
      .subscribe(institutions =>
        setInstitutions(institutions.map(institution => institution))
      );
    return () => subscription.unsubscribe();
  }, []);

  return institutions;
}

export function useInstitution(institutionId: string) {
  const [institution, setInstitution] = useState<FSInstitution | undefined>(
    undefined
  );
  useEffect(() => {
    const subscription = docData<FSInstitution>(
      getInstitutionsCollectionRef().doc(institutionId),
      'id'
    )
      .pipe(concat())
      .subscribe(institution => setInstitution(institution));
    return () => subscription.unsubscribe();
  }, [institutionId]);

  return institution;
}

export function useUnicGroups(user: User, unicGroupType?: UnicGroupType) {
  const [unicGroups, setUnicGroups] = useState<FSUnicGroup[] | undefined>(
    undefined
  );
  const { institutionId } = user;
  useEffect(() => {
    let ref;
    if (unicGroupType) {
      ref = getInstitutionsUnicGroupsCollectionRef(institutionId).where(
        'unicGroupType',
        '==',
        unicGroupType
      );
    } else {
      ref = getInstitutionsUnicGroupsCollectionRef(institutionId);
    }
    const subscription = collectionData<FSUnicGroup>(ref, 'id')
      .pipe(concat())
      .subscribe(unicGroups =>
        setUnicGroups(unicGroups.map(unicGroup => unicGroup))
      );
    return () => subscription.unsubscribe();
  }, [institutionId, unicGroupType]);

  return unicGroups;
}

export function useInstitutionUsers(institutionId: string) {
  const [users, setUsers] = useState<FSInstututionUser[] | undefined>(
    undefined
  );
  useEffect(() => {
    const subscription = collectionData<FSInstututionUser>(
      getInstitutionsUsersCollectionRef(institutionId),
      'id'
    )
      .pipe(concat())
      .subscribe(users => setUsers(users.map(user => user)));
    return () => subscription.unsubscribe();
  }, []);

  return users;
}
