import React, { Component } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { Link, LinkProps } from 'react-router-dom';

class MyLink extends Component<LinkProps> {
  render() {
    return <Link {...this.props} />;
  }
}
interface LinkButtonProps extends ButtonProps {
  to: string;
  linkProps?: LinkProps;
}

export default function LinkButton(props: LinkButtonProps) {
  // @ts-ignore
  return <MuiButton component={MyLink} {...props} />;
}
