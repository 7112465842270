import { Box, Fab, Typography } from '@material-ui/core';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import { LocationDescriptor } from 'history';
import { default as React, useState } from 'react';
import { isNull, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Link,
  LinkProps,
  matchPath,
  Route,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import styled from 'styled-components';
import { DesignPhase, designPhases } from '../../../constants/DesignPhases';
import { StorageReference } from '../../../firestore/collections/Storage';
import DesignPhaseIcon from '../../../icons/DesignPhaseIcon';
import { HOME_PATH } from '../../../routes/AppRouter';
import theme from '../../../style/theme';
import { GoBackButton, GoBackButtonProps } from '../../GoBackButton';
import { StorageMedia } from '../../StorageMedia';
import TFLogo from '../../TFLogo';
import DrawerMenu from './DrawerMenu';
import LogIcon from './LogIcon';
import DesignPhasesCircle from '../../DesignPhasesCircle';
import LinkButton from '../../LinkButton';
import { MenuIcon } from '../../../icons/Icons';
import SideMenuButton from '../../SideMenuButton';

export const buttonSize = 46;

const AppMenuList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;
  min-width: 10vh;
`;

export const StyledImageButton = styled(({ isFocused, color, ...other }) => (
  <div {...other} />
))`
  border-radius: ${buttonSize / 2}px;
  height: ${buttonSize}px;
  width: ${buttonSize}px;
  padding: 0 8px ${props => (!props.isFocused ? '3px' : 'none')} 8px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
  padding: 0 ${props => props.theme.spacing(2)}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  ${props => (!props.isFocused ? '-webkit-filter: grayscale(1)' : null)};
  margin: 7px 0 7px 0;
`;

export const StyledButton = styled(({ isFocused, color, ...other }) => (
  <div {...other} />
))`
  border-radius: ${buttonSize / 2}px;
  height: ${buttonSize}px;
  width: ${buttonSize}px;
  padding: 0 8px ${props => (!props.isFocused ? '3px' : 'none')} 8px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
  padding: 0 ${props => props.theme.spacing(2)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.isFocused ? props.color : props.theme.extraPalette.grey[300]};
  :hover {
    background-color: ${props =>
      props.isFocused ? props.color : props.theme.extraPalette.grey[400]};
    cursor: pointer;
  }
  color: ${props =>
    !props.isFocused
      ? props => props.theme.palette.text.primary
      : props.theme.palette.grey[50]};
  margin: 7px 0 7px 0;
`;

const HorizontalLine = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${props => props.theme.extraPalette.grey[500]};
`;

export const BorderDiv = styled(({ isFocused, ...other }) => (
  <div {...other} />
))`
  height: 100%;
  border-bottom: ${props =>
    props.isFocused
      ? `3px solid ${props.theme.extraPalette.grey[800]}`
      : 'none'};
`;
const BurgerButton = styled(Fab)`
  color: ${props => props.theme.extraPalette.red[500]} !important;
  background-color: transparent !important;
  box-shadow: none !important;
  align-self: center;
`;
interface AppMenuListItemLinkProps extends RouteComponentProps, LinkProps {
  designPhase: string;
}

const AppMenuListItemLink = withRouter((props: AppMenuListItemLinkProps) => {
  const focused = !isNull(
    matchPath(props.location.pathname, {
      path: props.to as string,
      exact: false,
      strict: false
    })
  );
  function navigateTo(destination: LocationDescriptor) {
    props.history.push(destination.toString());
  }

  const focusedColor = get(theme.designPhaseColors, props.designPhase)
    ? get(theme.designPhaseColors, props.designPhase)
    : theme.extraPalette.red[400];

  return (
    <BorderDiv isFocused={focused}>
      {props.designPhase === 'introduction' ? (
        <StyledImageButton
          isFocused={focused}
          onClick={() => navigateTo(props.to)}
        >
          {props.children}
        </StyledImageButton>
      ) : (
        <StyledButton
          isFocused={focused}
          color={focusedColor}
          onClick={() => navigateTo(props.to)}
        >
          {props.children}
        </StyledButton>
      )}
    </BorderDiv>
  );
});

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2em;
`;
const LogoLink = styled(Link)`
  text-decoration: none !important;
`;
const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
export const StyledStorageMedia = styled(StorageMedia)`
  height: 100%;
`;

export interface PhaseMenuProps
  extends RouteComponentProps<{ groupId?: string }>,
    WithWidthProps {
  goBackButton?: GoBackButtonProps;
  image: StorageReference;
  drawerTitle: string;
  children?: React.ReactNode;
}

const DesignPhaseMenu = function DesignPhaseMenu(props: PhaseMenuProps) {
  const [t] = useTranslation();
  const splitPathname = props.location.pathname.split('/');
  const currentPhase = splitPathname[splitPathname.length - 1];
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      {props.width === 'sm' || props.width === 'xs' ? (
        <BurgerButton onClick={() => setOpenDrawer(true)}>
          <MenuIcon />
        </BurgerButton>
      ) : props.goBackButton ? (
        <GoBackButton {...props.goBackButton} />
      ) : (
        <LogoContainer>
          <LogoLink to={HOME_PATH}>
            <TFLogo />
          </LogoLink>
        </LogoContainer>
      )}
      <CenterDiv>
        {props.width === 'sm' || props.width === 'xs' ? (
          <Typography
            variant="button"
            color="secondary"
            style={{ textTransform: 'capitalize' }}
          >
            {t(`phase_menu_titles.${currentPhase}`)}
          </Typography>
        ) : (
          <AppMenuList>
            <>
              <Route
                path={`${props.match.path}/introduction`}
                children={() => (
                  <AppMenuListItemLink
                    to={generatePath(props.match.path, {
                      ...props.match.params,
                      designPhase: 'introduction'
                    })}
                    designPhase={'introduction'}
                  >
                    <StyledStorageMedia storageReference={props.image} />
                  </AppMenuListItemLink>
                )}
              />
              <Box width={48} />
              {designPhases.map((designPhase, index) => (
                <React.Fragment key={designPhase}>
                  <Route
                    path={`${props.match.path}/${designPhase}`}
                    children={() => (
                      <AppMenuListItemLink
                        to={generatePath(props.match.path, {
                          ...props.match.params,
                          designPhase
                        })}
                        designPhase={designPhase}
                      >
                        <DesignPhaseIcon
                          fontSize="large"
                          designPhase={designPhase as DesignPhase}
                        />
                      </AppMenuListItemLink>
                    )}
                  />
                  {index < designPhases.length - 1 ? (
                    <HorizontalLine />
                  ) : (
                    <Box width={48} />
                  )}
                </React.Fragment>
              ))}
              <Route
                path={`${props.match.path}/introduction`}
                children={() => (
                  <AppMenuListItemLink
                    to={generatePath(props.match.path, {
                      ...props.match.params,
                      designPhase: 'log'
                    })}
                    designPhase={'log'}
                  >
                    <LogIcon style={{ margin: '3px 0 0 3px' }} />
                  </AppMenuListItemLink>
                )}
              />
            </>
          </AppMenuList>
        )}
      </CenterDiv>
      {props.children}
      <DrawerMenu
        type={'phase'}
        drawerTitle={props.drawerTitle}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        goBackButton={props.goBackButton}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box p={3}>
            <Typography variant="subtitle2" align="center">
              {t('burger_menu.click_to_add')}
            </Typography>
          </Box>
          <DesignPhasesCircle
            {...props}
            includeLog={true}
            size={235}
            iconScale={1.35}
            onCloseDrawer={() => setOpenDrawer(false)}
          />
          <Box height={30} />
          <LinkButton
            onClick={() => setOpenDrawer(false)}
            variant={'outlined'}
            to={generatePath(props.match.path, {
              ...props.match.params,
              designPhase: 'introduction'
            })}
          >
            {t('drawer_titles.course_introduction')}
          </LinkButton>
          <SideMenuButton to="../..">
            {t('burger_menu.back_to_courses')}
          </SideMenuButton>
        </div>
      </DrawerMenu>
    </>
  );
};

export default withWidth()(DesignPhaseMenu);
