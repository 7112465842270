import React from 'react';
import styled from 'styled-components';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const PlayerWrapper = styled.div`
  border-radius: ${props => props.theme.spacing(2)}px;
  overflow: hidden;
  box-shadow: ${props => props.theme.shadows[4]};
  position: relative;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

export default function ResponsivePlayer(props: ReactPlayerProps) {
  return (
    <PlayerWrapper>
      <StyledReactPlayer controls {...props} width="100%" height="100%" />
    </PlayerWrapper>
  );
}
