import { keyBy } from 'lodash';
import React, { useMemo } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useCurrentUser } from '../../../../components/Auth/AuthProvider';
import { FSUnicGroup } from '../../../../firestore/collections/Institutions/UnicGroups';
import {
  UnicGroupType,
  useUnicGroups
} from '../../../../firestore/models/Institution/hooks';
import theme from '../../../../style/theme';
import { useTranslation } from 'react-i18next';

interface SelectUnicGroupsProps {
  value: string[];
  onChange: (value: string[]) => void;
  unicGroupType?: UnicGroupType;
  myClasses?: string[];
}

interface MyUniCGroup extends FSUnicGroup {
  bold?: boolean;
}

const Label = styled.div<{
  bold: boolean;
}>`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => (props.bold ? theme.extraPalette.red[400] : 'inherit')};
`;

const formatOptionLabel = (props: MyUniCGroup) => {
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <Label bold={props.bold || false}>{props.unicName}</Label>
      </div>
    </div>
  );
};

export function SelectUnicGroups(props: SelectUnicGroupsProps) {
  const { myClasses } = props;
  const user = useCurrentUser();
  const unicGroups = useUnicGroups(user, props.unicGroupType);
  const unicGroupsById = useMemo(() => keyBy(unicGroups, 'id'), [unicGroups]);
  const [t] = useTranslation();

  const options = unicGroups ? (unicGroups as MyUniCGroup[]) : undefined;

  let allGroups;
  if (options) {
    allGroups = options.map(group => {
      group.bold = myClasses && myClasses.indexOf(group.id) > -1;
      return group;
    });
    allGroups.sort((a, b) => {
      return a.bold === b.bold ? 0 : a.bold ? -1 : 1;
    });
  }

  return (
    <>
      <Select
        placeholder={
          allGroups
            ? t(
                'course.classes_and_groups.select_classes_dialog_select_placeholder'
              )
            : t(
                'course.classes_and_groups.select_classes_dialog_select_placeholder_loading'
              )
        }
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        options={allGroups}
        getOptionLabel={option => option.unicName}
        getOptionValue={option => option.unicName}
        formatOptionLabel={formatOptionLabel}
        //@ts-ignore
        value={props.value.map(value => unicGroupsById[value])}
        onChange={(value: unknown) => {
          const unicValue = value as FSUnicGroup;
          props.onChange([unicValue.id]);
        }}
      />
    </>
  );
}
