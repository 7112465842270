import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/system';

export const Arrow = styled(({ ...otherProps }) => (
  <Typography {...otherProps} />
))<{}>`
  && {
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
`;

export const Text = styled(({ ...otherProps }) => (
  <Typography {...otherProps} />
))<{}>`
  && {
    &:focus,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

const LinkNoDecoration = styled(Link)`
  text-decoration: none;
`;

interface TextLinkProps extends LinkProps, TypographyProps {
  to: string;
  text: string;
  color: string;
  variant: string;
  align?: string;
  component?: string;
}

export default function TextLink(props: TextLinkProps) {
  const isMail = props.to.includes('mailto');
  if (isMail) {
    return (
      <a href={props.to} style={{ textDecoration: 'none' }}>
        <Arrow {...props}>→&nbsp;</Arrow>
        <Text {...props}>{props.text}</Text>
      </a>
    );
  } else {
    return (
      <>
        <LinkNoDecoration to={props.to}>
          <Arrow {...props}>→&nbsp;</Arrow>
        </LinkNoDecoration>
        <LinkNoDecoration to={props.to}>
          <Text {...props}>{props.text}</Text>
        </LinkNoDecoration>
      </>
    );
  }
}
