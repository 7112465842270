import React from 'react';
import styled from 'styled-components';
import { ArrowLeft } from '../icons/Icons';
import { withRouter, RouteComponentProps } from 'react-router';

export interface GoBackButtonProps extends RouteComponentProps {
  title: string;
  backLocation: string;
}

const StyledText = styled.p`
  color: ${props => props.theme.extraPalette.grey[600]};
  font-size: ${props => props.theme.typography.button.fontSize};
  font-weight: ${props => props.theme.typography.button.fontWeight};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const GoBackButton = withRouter((props: GoBackButtonProps) => {
  function handleGoBack() {
    props.history.push(props.backLocation);
  }

  return (
    <>
      <Container onClick={handleGoBack} style={{ cursor: 'pointer' }}>
        <ArrowLeft />
        <StyledText>{props.title}</StyledText>
      </Container>
    </>
  );
});
