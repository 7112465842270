import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default (props: LinkProps) => {
  return <StyledLink {...props} />;
};
