import FirestoreBaseModel from '../../FirestoreBase';
import { firebaseapp } from '../../../services/firebase';

export const INSTITUTIONS_COLLECTION = 'institutions';
export interface FSInstitution extends FirestoreBaseModel {
  unicAddress: string;
  unicCity: string;
  unicName: string;
  unicPostcode: string;
}

export function getInstitutionsCollectionRef() {
  return firebaseapp.firestore().collection(INSTITUTIONS_COLLECTION);
}
