import CssBaseline from '@material-ui/core/CssBaseline';
import NoSsr from '@material-ui/core/NoSsr';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import React, { Suspense, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import AppRouter from './routes/AppRouter';
import theme, { ThemeInterface } from './style/theme';
import i18n from './i18n';
import { LinearProgress } from '@material-ui/core';
import RootContainer from './components/RootContainer';
import AuthProvider from './components/Auth/AuthProvider';
import { SnackbarProvider } from 'notistack';
import { initFirebase } from './services/firebase';
import { ConfirmDialogProvider } from './components/ConfirmDialog';
import 'array-flat-polyfill';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { ImageDialogProvider } from './components/ImageDialog';

export const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
  appVersion: `${process.env.REACT_APP_VERSION}`
});
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

const _theme = createMuiTheme(theme);

function App() {
  const [firebaseInited, setFirebaseInited] = useState(false);
  useEffect(() => {
    (async () => {
      await initFirebase();
      setFirebaseInited(true);
    })();
  }, []);
  if (!firebaseInited) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<LinearProgress />}>
        <NoSsr>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider<ThemeInterface> theme={_theme as ThemeInterface}>
              <CssBaseline />
              <StyledThemeProvider theme={_theme as ThemeInterface}>
                <AuthProvider>
                  <RootContainer>
                    <SnackbarProvider>
                      <ConfirmDialogProvider>
                        <ImageDialogProvider>
                          <AppRouter />
                        </ImageDialogProvider>
                      </ConfirmDialogProvider>
                    </SnackbarProvider>
                  </RootContainer>
                </AuthProvider>
              </StyledThemeProvider>
            </ThemeProvider>
          </I18nextProvider>
        </NoSsr>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
