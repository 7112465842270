import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Hidden
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { LogOutIcon, UserIcon } from '../../icons/Icons';
import {
  getDisplayNameInitials,
  IsStudent,
  useCurrentUser,
  IsAdmin
} from '../Auth/AuthProvider';
import ActionMenu, { ActionMenuItemText, StyledBox } from './ActionMenu';
import { EditorAdminActionLinks } from '../../routes/EditorAdmin/components/EditorAdminActionLinks';

export const SignOutItem = withRouter((props: RouteComponentProps) => {
  const [t] = useTranslation();
  function signOut() {
    props.history.push('/login/signout');
  }
  return (
    <>
      <MenuItem onClick={() => signOut()}>
        <ListItemIcon>
          <LogOutIcon />
        </ListItemIcon>
        <ListItemText>
          <ActionMenuItemText>
            {t('action_menu.main.log_out')}
          </ActionMenuItemText>
        </ListItemText>
      </MenuItem>
    </>
  );
});

const MainActionMenu = withRouter((props: RouteComponentProps) => {
  const user = useCurrentUser();
  const [t] = useTranslation();

  const displayNameInitials = getDisplayNameInitials(user);

  return (
    <>
      <StyledBox>
        <Typography align="center" variant="caption" component="div">
          {t('action_menu.main.my_profile')}
        </Typography>
        <Typography align="center" color="secondary">
          {IsStudent(user)
            ? user.firebaseUser.displayName
            : displayNameInitials}
        </Typography>
      </StyledBox>
      {user.isPerm('editor') && <EditorAdminActionLinks />}
      <SignOutItem />
    </>
  );
});

export const AnchorText = styled(({ ...otherProps }) => (
  <Typography variant="caption" color="secondary" {...otherProps} />
))<{ customColor?: string }>`
  && {
    font-family: Montserrat;
    font-size: ${props => props.theme.typography.button.fontSize};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -0.2px;
    text-align: right;
    margin-right: 10px;
  }
`;

function Anchor() {
  const user = useCurrentUser();
  return (
    <>
      <Hidden xsDown>
        <AnchorText>
          {IsStudent(user)
            ? user.firebaseUser.displayName
            : getDisplayNameInitials(user)}
        </AnchorText>
      </Hidden>
      <UserIcon color="secondary" />
    </>
  );
}

export default () => (
  <ActionMenu renderAnchor={() => <Anchor />}>
    <MainActionMenu />
  </ActionMenu>
);
