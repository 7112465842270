import {
  FSCourse,
  FSCourseExercisesLists,
  FSCourseExercisesList
} from '../../collections/Courses';
import { FSExercise } from '../../collections/Exercises';
import { LessonDuration } from '../../../constants/LessonDuration';
import { FSInstitutionCourse } from '../../collections/Institutions/Courses';
import { firestore } from 'firebase';

export function getLessionsByDuration(duration: number) {
  return Math.ceil(duration / LessonDuration);
}

export function getLessions(course: FSCourse) {
  return course.exerciseLists.map(exercises =>
    getLessionsByDuration(exercises.duration)
  );
}

export function getExercisesDuration(
  allExercises: FSExercise[],
  exerciseRefs: firestore.DocumentReference[]
) {
  let sum = 0;
  exerciseRefs.forEach(exerciseRef => {
    let exercise = allExercises.find(
      exercise => exercise.id === exerciseRef.id
    );
    if (exercise) {
      sum += exercise.duration;
    }
  });
  return sum;
}

export function sortExerciseListsOnDuration(lists: FSCourseExercisesLists) {
  return lists.sort((a: FSCourseExercisesList, b: FSCourseExercisesList) => {
    if (a.custom) return 1;
    return b.duration - a.duration;
  });
}

export async function getCourseExercises(
  course: FSCourse
): Promise<FSExercise[]> {
  const snapshots = await Promise.all(
    course.exercises.map(docRef => docRef.get())
  );

  const exercises = snapshots.map(
    snapshot =>
      ({
        id: snapshot.id,
        documentRef: snapshot.ref,
        ...snapshot.data()
      } as FSExercise)
  );

  return exercises;
}

export async function getCourseExercisesByListIndex(
  course: FSCourse,
  exercisesListIndex: number
): Promise<FSExercise[]> {
  const snapshots = !course.exerciseLists[exercisesListIndex]
    ? []
    : await Promise.all(
        course.exerciseLists[exercisesListIndex].exercises.map(docRef =>
          docRef.get()
        )
      );

  const exercises = snapshots.map(
    snapshot =>
      ({
        id: snapshot.id,
        ...snapshot.data()
      } as FSExercise)
  );

  return exercises;
}

export function isCourseTemplate(course: unknown): course is FSCourse {
  return (
    course &&
    (course as FSCourse).exerciseLists !== undefined &&
    (course as FSInstitutionCourse).status === undefined &&
    (course as FSInstitutionCourse).ownerUID === undefined
  );
}

export function isCourseInstance(
  course: unknown
): course is FSInstitutionCourse {
  return (
    course &&
    (course as FSInstitutionCourse).exerciseLists !== undefined &&
    (course as FSInstitutionCourse).status !== undefined &&
    (course as FSInstitutionCourse).ownerUID !== undefined
  );
}

export function isCourseEditable(course: any) {
  const courseStatus = isCourseInstance(course)
    ? (course as FSInstitutionCourse).status
    : 'draft';

  return (course && isCourseTemplate(course)) || courseStatus === 'draft';
}

export function filterExerciseListsByIndex(
  lists: FSCourseExercisesList[],
  indexAt: number
) {
  return lists.filter((list, index) => {
    return indexAt === index;
  });
}
