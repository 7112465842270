import FirestoreBaseModel from '../../FirestoreBase';
import { firebaseapp } from '../../../services/firebase';

export const ROLE_RANKS_COLLECTION = 'roleRanks';
export interface FSRoleRank extends FirestoreBaseModel {
  rank: number;
}

export function getRoleRanksCollectionRef() {
  return firebaseapp.firestore().collection(ROLE_RANKS_COLLECTION);
}
