import React from 'react';
//import TFLogo from '../assets/tf-logo.svg';
import GylLogo from '../assets/img/app-bar-gyldendal.png';
import TFLogo from '../assets/img/app-bar-tf.png';
import { Hidden } from '@material-ui/core';

export default () => (
  <>
    <img src={GylLogo} alt="logo" />
    <Hidden mdDown>
      <img src={TFLogo} alt="logo" />
    </Hidden>
  </>
);
