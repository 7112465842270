import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FSCourse } from '../firestore/collections/Courses';
import { isCourseInstance } from '../firestore/models/Course/helpers';
import { dateFormat } from '../i18n/date';
import { useCurrentUser } from './Auth/AuthProvider';
import LargeCard, { CourseCardSubText } from './Cards/LargeCard';
import { StorageMedia } from './StorageMedia';
import styled from 'styled-components';

interface CoursesGridProps {
  courses: FSCourse[];
  getLink: (courseId: string) => string;
}

const CardWrapper = styled.div<{ custom: boolean }>`
  background-color: ${props =>
    props.custom ? props.theme.extraPalette.blue[500] : null};
  padding: ${props => (props.custom ? '6px' : null)};
  border-radius: ${props => (props.custom ? '12px' : null)};
`;

export default function CoursesGrid(props: CoursesGridProps) {
  const [t] = useTranslation();
  const user = useCurrentUser();
  const isTeacher = user && user.isPerm('teacher');

  return (
    <Grid container spacing={8}>
      {props.courses.map((course, index) => {
        let subLeft: string | (() => React.ReactNode) = `→ ${t(
          `courses.${course.level}`
        )}`;
        let subRight: undefined | (() => React.ReactNode) = undefined;
        if (isCourseInstance(course)) {
          if (isTeacher) {
            if (course.unicGroups.length > 0) {
              subLeft = course.unicGroups.join(', ');
            } else {
              subLeft = t(`courses_overview.course_no_class_yet`);
            }
          }
          subRight = () => {
            if (course.status === 'ended' && course.endedTime) {
              return (
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Box flex={1}>
                    <CourseCardSubText variant="caption">
                      {t(`course_status.${course.status}`)}
                    </CourseCardSubText>
                  </Box>
                  <Box flex={1}>
                    <CourseCardSubText variant="caption">
                      {dateFormat(course.endedTime.toDate(), 'D. MMM. YYYY')}
                    </CourseCardSubText>
                  </Box>
                </Box>
              );
            } else if (
              course.status === 'draft' &&
              course.edited &&
              course.edited.timestamp
            ) {
              return (
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Box flex={1}>
                    <CourseCardSubText variant="caption">
                      Redigeret
                    </CourseCardSubText>
                  </Box>
                  <Box flex={1}>
                    <CourseCardSubText variant="caption">
                      {dateFormat(
                        course.edited.timestamp.toDate(),
                        'D. MMM. YYYY'
                      )}
                    </CourseCardSubText>
                  </Box>
                </Box>
              );
            } else {
              return (
                <CourseCardSubText variant="caption">
                  {t(`course_status.${course.status}`)}
                </CourseCardSubText>
              );
            }
          };
        }
        return (
          <Grid item key={course.id} xs={12} sm={6} md={6} lg={4}>
            <StorageMedia storageReference={course.mainImage} width={600}>
              {url => (
                <CardWrapper
                  custom={
                    isTeacher && course.customized ? course.customized : false
                  }
                >
                  <LargeCard
                    imageLink={props.getLink(course.id)}
                    title={course.title}
                    shortDescription={course.subtitle}
                    subLeft={subLeft}
                    subRight={subRight}
                    buttonText={t(`courses_overview.open`)}
                    image={url}
                    disabled={course.activated === false ? true : false}
                  />
                </CardWrapper>
              )}
            </StorageMedia>
          </Grid>
        );
      })}
    </Grid>
  );
}
