import React, { useState, useEffect, useContext } from 'react';
import { firebaseapp, firebase } from '../../services/firebase';
import { isObject, mapValues, keyBy } from 'lodash';

import { getUsersCollectionRef } from '../../firestore/collections/Users';
import { docData } from 'rxfire/firestore';
import { Role } from '../../constants/Roles';
import {
  getInstitutionsUsersCollectionRef,
  FSInstututionUser
} from '../../firestore/collections/Institutions/Users';
import { getRoleRanksCollectionRef } from '../../firestore/collections/RoleRanks';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useInstitutions } from '../../firestore/models/Institution/hooks';
import Select from 'react-select';
import { FSInstitution } from '../../firestore/collections/Institutions';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { firestore, database } from 'firebase/app';
import { bugsnagClient } from '../../App';

var isOfflineForFirestore = {
  state: 'offline',
  last_changed: firestore.FieldValue.serverTimestamp()
};
var isOnlineForFirestore = {
  state: 'online',
  last_changed: firestore.FieldValue.serverTimestamp()
};
var isOfflineForDatabase = {
  state: 'offline',
  last_changed: database.ServerValue.TIMESTAMP
};

var isOnlineForDatabase = {
  state: 'online',
  last_changed: database.ServerValue.TIMESTAMP
};

export interface User {
  role: Role;
  institutionId: string;
  institutionUser: FSInstututionUser;
  firebaseUser: firebase.User;
  isPerm: (leastRequiredRole: Role) => boolean;
  isRole: (role: Role) => boolean;
  userDocRef: firebase.firestore.DocumentReference;
  hasLicense: boolean;
}

interface RoleRanks {
  [role: string]: number;
}

function UserInstance(
  institutionUser: FSInstututionUser,
  firebaseUser: firebase.User,
  idTokenResult: firebase.auth.IdTokenResult,
  roleRanks: RoleRanks,
  userDocRef: firebase.firestore.DocumentReference
): User {
  const userInstance = {
    role: idTokenResult.claims.role,
    institutionId: idTokenResult.claims.institutionId,
    institutionUser,
    firebaseUser,
    userDocRef,
    hasLicense:
      idTokenResult.claims.license || idTokenResult.claims.role === 'admin'
  };
  return {
    ...userInstance,
    isPerm: leastRequiredRole =>
      isPerm(leastRequiredRole, userInstance.role, roleRanks),
    isRole: role => isRole(role, userInstance.role)
  };
}

export function IsStudent(user: User) {
  return user.role === 'student';
}
export function IsAdmin(user: User) {
  return user.role === 'admin';
}

export function isPerm(
  leastRequiredRole: Role,
  userRole: Role,
  roleRanks: RoleRanks
): boolean {
  return roleRanks[userRole] >= roleRanks[leastRequiredRole];
}

export function isRole(role: Role, userRole: Role): boolean {
  return userRole === role;
}

export function getDisplayNameInitials(user: User): string {
  return user.firebaseUser.displayName
    ? user.firebaseUser.displayName
        .split(' ')
        .map(n => n[0])
        .join('.')
    : '';
}

export interface AuthContextValue {
  currentUser: User | null | undefined;
  isLoading: boolean;
  checkAuth: () => void;
}

export const AuthContext = React.createContext<AuthContextValue>({
  currentUser: undefined,
  isLoading: true,
  checkAuth: () => null
});

export function useIsSignedIn() {
  const currentUser = useCurrentUser();
  return !!currentUser;
}

/**
 * Returns the current logged ind user.
 * Only use when you are sure that user is logged in.
 * Eg. if user is under a protected route.
 */
export function useCurrentUser(): User {
  return useContext(AuthContext).currentUser as User;
}

export function useAuthIsLoading() {
  return useContext(AuthContext).isLoading;
}

export function useCheckAuth() {
  return useContext(AuthContext).checkAuth();
}

export function useSignout() {
  const currentUser = useCurrentUser();
  const auth = firebaseapp.auth();
  return () => {
    var userStatusDatabaseRef = firebaseapp
      .database()
      .ref('/status/' + currentUser.userDocRef.id);
    return userStatusDatabaseRef.set(isOfflineForDatabase).then(() => {
      auth.signOut();
    });
  };
}

/**
 * returns [currentUser | null, isLoading]
 */
export function useAuth(): [User | null, boolean] {
  const auth = useContext(AuthContext);
  return [auth.currentUser || null, auth.isLoading];
}

interface AdminSelectInstitutionProps {
  onChange: (institutionId: string) => void;
}

function AdminSelectInstitution(props: AdminSelectInstitutionProps) {
  const institutions = useInstitutions();
  const [institution, setInstitution] = useState<FSInstitution | null>(null);
  const [loadingFromSession, setLoadingFromSession] = useState<boolean>(true);
  const [saveChoice, setSaveChoice] = useState<boolean>(true);
  const [t] = useTranslation();
  const { onChange } = props;
  useEffect(() => {
    const fromStorage = window.sessionStorage.getItem('admin_institution_id');
    if (typeof fromStorage === 'string') {
      onChange(fromStorage);
    } else {
      setLoadingFromSession(false);
    }
  }, [onChange]);

  if (loadingFromSession) {
    return null;
  }

  return (
    <Dialog open>
      <DialogTitle>Vælg hvilken institution du vil arbejde på</DialogTitle>
      <DialogContent style={{ minHeight: 150 }}>
        <DialogContentText color="secondary">
          Da du er øverste administrator er du ikke tilknyttet en specifik
          institution. Vælg en institution fra listen:
        </DialogContentText>
        <Box my={1} mb={3}>
          <Select<FSInstitution>
            isLoading={!institutions}
            options={institutions}
            getOptionLabel={option => option.unicName}
            getOptionValue={option => option.id}
            // @ts-ignore
            value={institution}
            // @ts-ignore
            onChange={option => option && setInstitution(option)}
          />
        </Box>
        <DialogContentText variant="body2">
          Hvis den institution du vil arbejde på ikke er på listen, har ingen
          brugere fra den pågældende institution logget ind på platformen endnu.
        </DialogContentText>
        <DialogActions>
          <FormControlLabel
            label="Husk i denne session"
            control={
              <Checkbox
                onChange={e => setSaveChoice(e.currentTarget.checked)}
                checked={saveChoice}
              />
            }
          />

          <Button
            disabled={!isObject(institution)}
            onClick={() => {
              if (isObject(institution)) {
                if (saveChoice) {
                  window.sessionStorage.setItem(
                    'admin_institution_id',
                    institution.id
                  );
                }
                onChange(institution.id);
              }
            }}
          >
            {t('ok')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export default function AuthProvider(props: AuthProviderProps) {
  const auth = firebaseapp.auth();
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState(true);

  const [userInvalid, setUserInvalid] = useState(false);

  function checkAuth() {
    const auth = firebaseapp.auth();
    if (currentUser && !auth.currentUser) {
      setCurrentUser(null);
    }
  }

  useEffect(() => {
    firebaseapp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  }, []);

  useEffect(() => {
    firebaseapp
      .database()
      .ref('.info/connected')
      .on('value', snap => {
        if (currentUser) {
          var userStatusFirestoreRef = firebaseapp
            .firestore()
            .doc('/userPresence/' + currentUser.userDocRef.id);

          var userStatusDatabaseRef = firebaseapp
            .database()
            .ref('/status/' + currentUser.userDocRef.id);
          const connected = Boolean(snap.val());
          if (connected) {
            userStatusDatabaseRef
              .onDisconnect()
              .set(isOfflineForDatabase)
              .then(() => {
                userStatusDatabaseRef.set(isOnlineForDatabase);
                userStatusFirestoreRef.set(isOnlineForFirestore);
              });
          } else {
            userStatusFirestoreRef.set(isOfflineForFirestore);
            userStatusDatabaseRef.set(isOfflineForDatabase);
          }
        }
      });
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
        setIsLoading(true);
        if (firebaseUser) {
          const idTokenResult = await firebaseUser.getIdTokenResult();
          if (
            !idTokenResult ||
            (idTokenResult.claims &&
              typeof idTokenResult.claims['role'] !== 'string')
          ) {
            bugsnagClient.notify(
              new Error(`User has no role! ${firebaseUser.uid}`),
              { metaData: { userId: firebaseUser.uid, idTokenResult } }
            );
            setUserInvalid(true);
            setIsLoading(false);
            setCurrentUser(null);
            auth.signOut();
            return;
          }
          const institutionId = idTokenResult.claims.institutionId;

          if (
            typeof institutionId !== 'string' &&
            idTokenResult.claims.role !== 'admin'
          ) {
            bugsnagClient.notify(
              new Error(
                `User has no institution id and is not an admin! ${firebaseUser.uid}`
              ),
              { metaData: { userId: firebaseUser.uid, idTokenResult } }
            );
            setUserInvalid(true);
            setIsLoading(false);
            setCurrentUser(null);
            auth.signOut();
            return;
          }

          // Check that info about license is present and signout if not.
          if (
            typeof idTokenResult.claims.license === 'undefined' &&
            idTokenResult.claims.role !== 'admin'
          ) {
            setUserInvalid(true);
            setIsLoading(false);
            setCurrentUser(null);
            auth.signOut();
            return;
          }

          let usersCollectionRef = getUsersCollectionRef();
          if (institutionId) {
            usersCollectionRef = getInstitutionsUsersCollectionRef(
              institutionId
            );
          }
          const userDocRef = usersCollectionRef.doc(firebaseUser.uid);
          const roleRanks = mapValues(
            keyBy(
              await (await getRoleRanksCollectionRef().get()).docs.map(snap => {
                return { rank: snap.get('rank'), role: snap.id };
              }),
              'role'
            ),
            r => r.rank
          );
          docData<FSInstututionUser>(userDocRef, 'id').subscribe(async user => {
            const userInstance = UserInstance(
              user,
              firebaseUser,
              idTokenResult,
              roleRanks,
              userDocRef
            );
            setCurrentUser(userInstance);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
          setCurrentUser(null);
          auth.signOut();
        }
        return unsubscribe;
      });
    })();
  }, []);

  if (userInvalid) {
    return (
      <Dialog open>
        <DialogTitle>Øv</DialogTitle>
        <DialogContent>
          Der skete en fejl, ved indlæsningen af din bruger. Prøv at logge ind
          igen. Hvis det ikke virker, kontakt da support.
        </DialogContent>
        <DialogActions>
          <Button href="/">Gå til forside</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isLoading,
        checkAuth
      }}
    >
      <>
        {currentUser &&
        currentUser.isPerm('admin') &&
        !currentUser.institutionId ? (
          <AdminSelectInstitution
            onChange={institutionId =>
              setCurrentUser({ ...currentUser, institutionId })
            }
          />
        ) : (
          props.children
        )}
      </>
    </AuthContext.Provider>
  );
}
