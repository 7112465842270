import { StorageReference, FSStorage } from '../../collections/Storage';
import { useState, useEffect } from 'react';
import { uniqBy, keyBy } from 'lodash';
import { docData } from 'rxfire/firestore';
import { getStorageUsedByRef } from '../../collections/StorageUsedBy';

export function useStorageItem(storageReference: StorageReference) {
  const [item, setItem] = useState<FSStorage | undefined>(undefined);
  useEffect(() => {
    const subscription = docData<FSStorage>(storageReference.storageRef, 'id')
      .pipe()
      .subscribe(storage => setItem(storage));
    return () => subscription.unsubscribe();
  }, [storageReference.storageRef, storageReference.storageRef.id]);

  return item;
}

export function useStorageItems(storageReferences: StorageReference[]) {
  const [items, setItems] = useState<FSStorage[] | undefined>(undefined);
  useEffect(() => {
    (async () => {
      const items = await Promise.all(
        storageReferences.map(async s => {
          const snap = await s.storageRef.get();
          return { ...snap.data(), id: snap.id } as FSStorage;
        })
      );
      setItems(items);
    })();
  }, [storageReferences]);

  return items;
}

export function useStorageUrlWithCache(
  storageReference: StorageReference | undefined
) {
  const [data, setData] = useState<
    { url: string; mimetype: string; storageUrl: string } | undefined
  >(undefined);

  const storageRef = storageReference && storageReference.storageRef;
  const id = storageRef && storageRef.id;

  useEffect(() => {
    (async () => {
      if (!storageRef) {
        return;
      }
      const sessionUrl = window.sessionStorage.getItem(`storage_id_url_${id}`);
      const sessionMimetype = window.sessionStorage.getItem(
        `storage_id_mime_${id}`
      );
      const storageUrl = window.sessionStorage.getItem(`storage_id_surl_${id}`);

      if (sessionUrl && sessionMimetype && storageUrl) {
        return setData({
          url: sessionUrl,
          mimetype: sessionMimetype,
          storageUrl: storageUrl
        });
      }
      const snapshot = await storageRef.get();
      if (!snapshot.exists) {
        console.warn(`Storage media with id ${snapshot.id} does not exist.`);
        return;
      }
      const storage = { ...snapshot.data(), id: snapshot.id } as FSStorage;
      window.sessionStorage.setItem(
        `storage_id_url_${storage.id}`,
        storage.url
      );
      window.sessionStorage.setItem(
        `storage_id_mime_${storage.id}`,
        storage.mimetype
      );
      window.sessionStorage.setItem(
        `storage_id_surl_${storage.id}`,
        storage.storageRef
      );
      setData({
        url: storage.url,
        mimetype: storage.mimetype,
        storageUrl: storage.storageRef
      });
    })();
  }, [storageRef, id]);

  return data;
}

// needs refactoring
export function useStorageByStorageRefs(
  storageRefs: StorageReference[] = []
): { [id: string]: FSStorage } {
  const [storage, setStorage] = useState<any>(undefined);
  const _storageDep = storageRefs.map(a => a.storageRef.id).join();
  useEffect(() => {
    if (storageRefs.length > 0) {
      setStorage(undefined);
      (async () => {
        const _st = await (await Promise.all(
          uniqBy(storageRefs, s => s.storageRef.id).map(ref =>
            ref.storageRef.get()
          )
        )).map(snap => {
          if (snap.exists) {
            return { id: snap.id, ...snap.data() } as FSStorage;
          }
        });
        const a = keyBy(_st, 'id');
        setStorage(a);
      })();
    }
  }, [_storageDep]);
  return storage;
}

export function useStorageUsedByDocument(
  document?: firebase.firestore.DocumentReference
) {
  const [storage, setStorage] = useState<StorageReference[] | undefined>(
    undefined
  );
  useEffect(() => {
    if (document) {
      (async () => {
        const queryResults = await getStorageUsedByRef()
          .where('documents', 'array-contains', document)
          .get();
        const storageRefs = queryResults.docs.map(
          snapshot =>
            ({
              ...snapshot.data()
            } as StorageReference)
        );
        setStorage(storageRefs);
      })();
    }
  }, [document]);
  return storage;
}
