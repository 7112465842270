import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SelectUnicGroups } from './SelectUnicGroups';

interface SelectClassesDialogProps {
  open: boolean;
  classes: string[];
  myClasses: string[];
  onSave: (classes: string[]) => void;
  onClose?: () => void;
  title: string;
}

const DialogFrame = styled.div`
  margin: ${props => props.theme.spacing(4)}px;
`;
const DialogActionsFrame = styled(DialogActions)`
  margin-top: ${props => props.theme.spacing(1)}px;
`;

export function SelectClassesDialog(props: SelectClassesDialogProps) {
  const [classes, setClasses] = useState<string[]>(props.classes);
  const [t] = useTranslation();

  return (
    <Dialog onClose={props.onClose} fullWidth open={props.open}>
      <DialogFrame>
        <DialogTitle style={{ marginBottom: 20 }}>{props.title}</DialogTitle>
        <DialogContent>
          <SelectUnicGroups
            value={classes}
            myClasses={props.myClasses}
            onChange={value => setClasses(value)}
          />
        </DialogContent>
        <DialogActionsFrame>
          <Button
            style={{ marginRight: 30 }}
            variant="outlined"
            color="primary"
            onClick={props.onClose}
          >
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={() => props.onSave(classes)}>
            {t('select')}
          </Button>
        </DialogActionsFrame>
      </DialogFrame>
    </Dialog>
  );
}
