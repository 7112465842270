import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

function loadLocales(
  url: string,
  options: any,
  callback: (locale: string | null, status: any) => void,
  data: any
) {
  import(`./locales/${url}.json`)
    .then(locale => {
      callback(locale, { status: '200' });
    })
    .catch(err => {
      callback(null, { status: '404' });
    });
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // if not using I18nextProvider
  .init({
    backend: {
      loadPath: '{{lng}}',
      parse: (data: any) => data,
      ajax: loadLocales
    },
    fallbackLng: 'da',
    // debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    // react i18next special options (optional)
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  });

export default i18n;
