import { Button, Menu, Typography, Box } from '@material-ui/core';
import React, { SyntheticEvent, useContext, useState } from 'react';
import styled from 'styled-components';

export const AnchorOverline = styled.div`
  && {
    display: flex;
    font-size: ${props => props.theme.cardOverline.fontSize}px;
    font-weight: ${props => props.theme.cardOverline.fontWeight};
    line-height: ${props => props.theme.cardOverline.lineHeight};
    color: ${props => props.theme.extraPalette.grey[900]};
  }
`;

export const ActionMenuItemText = styled(({ ...otherProps }) => (
  <Typography variant="subtitle2" {...otherProps} />
))<{}>``;

export const AnchorStatus = styled(({ statusColor, ...otherProps }) => (
  <Typography {...otherProps} />
))<{ statusColor?: string }>`
  && {
    color: ${props => props.statusColor};
    display: flex;
  }
`;

export const StyledBox = styled(Box)`
  && {
    position: relative;
    top: -10px;
    padding: 20px;
    background-color: ${props => props.theme.extraPalette.grey[200]};
    outline: none;
    margin-bottom: -10px;
  }
`;

interface ActionMenuProps {
  //userAlias:string;
  children?: React.ReactNode;
  renderAnchor: (props: { handleClose: () => void }) => React.ReactNode;
}

const ActionMenuContext = React.createContext<{ close: () => void }>({
  close: () => null
});

export function useActionMenu() {
  return useContext(ActionMenuContext);
}

export default function ActionMenu(props: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<any | null | undefined>(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function openMenu(event: SyntheticEvent) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Button
        aria-owns={anchorEl ? 'action-menu' : undefined}
        aria-haspopup="true"
        onClick={event => openMenu(event)}
        style={{ textDecoration: 'none', margin: 0 }}
      >
        {props.renderAnchor({ handleClose })}
      </Button>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          style: {
            borderRadius: 10,
            minWidth: 250,
            maxWidth: 300,
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <ActionMenuContext.Provider value={{ close: handleClose }}>
          {props.children}
        </ActionMenuContext.Provider>
      </Menu>
    </>
  );
}
