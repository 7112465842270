import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import Body, { Content } from '../../components/Body';
import Header from '../../components/Header/Header';
import MainMenu from '../../components/Header/components/MainMenu';
import MainActionMenu from '../../components/ActionMenu/MainActionMenu';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Button, Hidden, Link } from '@material-ui/core';
import { useIsSignedIn } from '../../components/Auth/AuthProvider';
import { HOME_PATH } from '../AppRouter';
import Illustration from '../../assets/nolicense/pcthumbsup.png';
import styled from 'styled-components';

const ParagraphImage = styled.img`
  && {
    width: 100%;
    max-width: 210px;
  }
`;

const SectionButton = styled(Button)`
  && {
    min-width: 200px;
  }
`;

const HeadLine = styled(Typography)`
  && {
    white-space: pre-line;
    ${props => props.theme.breakpoints.down('sm')} {
      font-size: ${props => props.theme.typography.h3.fontSize};
    }
  }
`;

export function NoLicense(props: RouteComponentProps) {
  const [t] = useTranslation();
  const isSignedIn = useIsSignedIn();

  if (!isSignedIn) {
    return <Redirect to={HOME_PATH} />;
  }

  return (
    <>
      <Header>
        <MainMenu
          drawerTitle={t('drawer_titles.login')}
          actionMenu={<MainActionMenu />}
          items={[]}
        />
      </Header>
      <Body>
        <Content>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={9}>
              <HeadLine
                variant="h2"
                paragraph
                color="secondary"
                style={{ lineHeight: 'normal' }}
              >
                {t('no_license.title')}
              </HeadLine>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={3}>
                <ParagraphImage src={Illustration} alt="" />
              </Grid>
            </Hidden>
          </Grid>

          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} sm={8} md={6}>
              <Typography variant="body1">
                {t('no_license.test_login_text')}
              </Typography>
            </Grid>
            <Grid item>
              <Link
                href="https://response.questback.com/isa/qbv.dll/ShowQuest?QuestID=5425166&sid=HiIvxvTWWL"
                target="_blank"
              >
                <SectionButton variant="outlined">
                  {t('no_license.test_login_button')}
                </SectionButton>
              </Link>
            </Grid>
            <Grid item>
              <Grid>
                <SectionButton href="/" variant="contained">
                  Til forsiden
                </SectionButton>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Body>
    </>
  );
}
