export const grey = {
  '50': '#ffffff',
  '100': '#fafafa',
  '200': '#f5f5f5',
  '300': '#f0f0f0',
  '400': '#dedede',
  '500': '#c2c2c2',
  '600': '#979797',
  '700': '#818181',
  '800': '#606060',
  900: '#3c3c3c',
  A100: '#f2f2f2',
  A200: '#ededed',
  A400: '#d6d6d6',
  A700: '#797979'
};

export const red = {
  50: '#FFE9F0',
  100: '#FFC9D6',
  200: '#F8919F',
  300: '#F16278',
  400: '#FF2C57',
  500: '#FF2D55',
  600: '#F7003C',
  700: '#E50035',
  800: '#D9002D',
  900: '#C90021',
  A100: '#ffffff',
  A200: '#fffafa',
  A400: '#ffc7cb',
  A700: '#ffadb4',
  contrastDefaultColor: 'light'
};

export const green = {
  50: '#e9f7ef',
  100: '#c9ebd8',
  200: '#a5debe',
  300: '#80d1a3',
  400: '#65c790',
  500: '#009A9A',
  600: '#43b774',
  700: '#3aae69',
  800: '#32a65f',
  900: '#22984c',
  A100: '#d5ffe3',
  A200: '#a2ffc1',
  A400: '#6fff9e',
  A700: '#56ff8d',
  contrastDefaultColor: 'dark'
};

export const orange = {
  50: '#ffefeb',
  100: '#ffd7ce',
  200: '#ffbcae',
  300: '#ffa18d',
  400: '#ff8c74',
  500: '#FF785A',
  600: '#ff7054',
  700: '#ff654a',
  800: '#ff5b41',
  900: '#ff4830',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffdfdb',
  A700: '#ffc8c2',
  contrastDefaultColor: 'dark'
};

export const purple = {
  50: '#f5e7f6',
  100: '#e5c4e8',
  200: '#d49cd8',
  300: '#c374c8',
  400: '#b657bd',
  500: '#912891',
  600: '#a233aa',
  700: '#982ca1',
  800: '#8f2498',
  900: '#7e1788',
  A100: '#f9beff',
  A200: '#f48bff',
  A400: '#f058ff',
  A700: '#ee3fff',
  contrastDefaultColor: 'light'
};

export const blue = {
  50: '#e0ecfc',
  100: '#b3d0f8',
  200: '#80b1f4',
  300: '#4d92ef',
  400: '#267aeb',
  500: '#0064EB',
  600: '#005be5',
  700: '#0051e2',
  800: '#0047de',
  900: '#191464',
  A100: '#ffffff',
  A200: '#cdd6ff',
  A400: '#9aacff',
  A700: '#8097ff',
  contrastDefaultColor: 'light'
};

export const sand = {
  50: '#fdfdfc',
  100: '#faf9f8',
  200: '#f7f5f4',
  300: '#f3f1ee',
  400: '#f1eeeb',
  500: '#eeebe7',
  600: '#ece9e4',
  700: '#e7e3de',
  800: '#e7e2dd',
  900: '#e2ddd7',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: 'dark'
};

export const yellow = {
  50: '#fefbf0',
  100: '#fef6da',
  200: '#fdf0c1',
  300: '#fceaa8',
  400: '#fbe695',
  500: '#fae182',
  600: '#f9dd7a',
  700: '#f9d96f',
  800: '#f8d565',
  900: '#f6cd52',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#fffbef',
  A700: '#fff4d5',
  contrastDefaultColor: 'light'
};
