import FirestoreBaseModel from '../../FirestoreBase';
import { firebaseapp } from '../../../services/firebase';

export const STORAGE_USED_BY_COLLECTION = 'storageUsedBy';
export interface FSStorageUsedBy extends FirestoreBaseModel {
  documents: Array<firebase.firestore.DocumentReference>;
  storageRef: firebase.firestore.DocumentReference;
}

export function getStorageUsedByRef() {
  return firebaseapp.firestore().collection(STORAGE_USED_BY_COLLECTION);
}
