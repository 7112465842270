import { firebaseapp } from '../../../services/firebase';
import FirestoreBaseModel from '../../FirestoreBase';
import { StorageReference } from '../Storage';

export const COURSES_COLLECTION = 'courses';
export const TEACHERS_GUIDE_COLLECTION = 'teachersGuide';

export interface FSCourseTrainingCourse {
  requiredAssignmentRefs: Array<firebase.firestore.DocumentReference>;
  requiredMaterials: string;
  solutionExample: string;
  teachersGuide: string;
  trainingCourseRef: firebase.firestore.DocumentReference;
}

export interface FSCourseExercisesList {
  duration: number;
  exercises: Array<firebase.firestore.DocumentReference>;
  learningObjectives: Array<string>;
  custom: boolean;
}

export type FSCourseExercisesLists = Array<FSCourseExercisesList>;

export type FSCourseExercises = Array<firebase.firestore.DocumentReference>;

export type CourseTemplateStatus = 'draft' | 'published';

export interface FSCourse extends FirestoreBaseModel {
  title: string;
  subtitle: string;
  introduction: string;
  customized?: boolean;
  authors: string[];
  mainImage: StorageReference;
  created: {
    timestamp: firebase.firestore.Timestamp;
    user?: string;
  };
  edited: {
    timestamp: firebase.firestore.Timestamp;
    user: string;
  };
  exerciseLists: FSCourseExercisesLists;
  exercises: FSCourseExercises;
  level: string;
  subjects: Array<firebase.firestore.DocumentReference>;
  trainingCourses: Array<FSCourseTrainingCourse>;
  documents: Array<StorageReference>;
  courseTemplateStatus: CourseTemplateStatus;
  activated: boolean;
}

export function getCoursesCollectionRef() {
  return firebaseapp.firestore().collection(COURSES_COLLECTION);
}
export function getCoursesTeachersGuideCollectionRef(courseId?: string) {
  return firebaseapp
    .firestore()
    .collection(
      `${COURSES_COLLECTION}/${courseId}/${TEACHERS_GUIDE_COLLECTION}`
    );
}
