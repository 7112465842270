import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { withTheme } from 'styled-components';
import { ThemeInterface } from '../../../style/theme';

interface DesignPhaseIconProps extends SvgIconProps {
  withColor?: boolean | string;
  theme: ThemeInterface;
}

export default withTheme(
  ({ withColor, theme, ...otherProps }: DesignPhaseIconProps) => (
    <SvgIcon
      {...otherProps}
      htmlColor={typeof withColor === 'string' ? withColor : undefined}
    >
      <path
        id="a"
        d="M17.509 20.415a47.841 47.841 0 0 1-9.474.816 62.114 62.114 0 0 0 8.927-.955l.295-.054.889-18.178.062-.013-.7 18.384zm.675-19.16L19 1.08l-.76 19.971-.294.057a48.623 48.623 0 0 1-16.027.419l.052-.38c-.066-.004-.13-.005-.196-.01l-.343-.022-.067-2.345-.109.337c-.795-.262-1.342-1.123-1.245-1.961.097-.838.825-1.55 1.658-1.62.832-.071 1.668.51 1.9 1.322l-.726.212c-.134-.467-.634-.81-1.111-.773-.48.04-.915.465-.97.947-.05.432.207.888.59 1.087l-.022-.741.755-.022.082 2.84c4.8.292 9.629.015 14.365-.82L17.451.79 2.469 1.3l-.024 1.066-.755-.018.04-1.785L18.246 0l-.062 1.256zM.876 11.458c.195-.154.417-.264.65-.329l.068-.765C.97 10.328.374 9.904.18 9.293c-.237-.748.172-1.626.894-1.918.114-.047.235-.078.36-.097.01-.462.018-.793.026-1.027C.902 6.034.463 5.509.405 4.894c-.081-.855.59-1.68 1.436-1.767.844-.082 1.667.586 1.757 1.44l-.75.08c-.047-.435-.498-.806-.931-.761-.433.044-.802.499-.76.935.024.254.187.482.403.625.006-.246.014-.572.023-1.015l.755.015a106.9 106.9 0 0 1-.035 1.406c-.008.232-.016.437-.093.569-.006.217-.013.515-.021.905a2.3 2.3 0 0 1 .421.153c.433.206.78.51 1.117.805l-.495.576c-.298-.26-.605-.53-.944-.691-.334-.16-.674-.19-.933-.086C1 8.226.783 8.693.9 9.06c.1.312.435.54.761.545l.064-.716.752.067-.187 2.122c.163.025.323.074.471.147.647.321 1.01 1.076.842 1.757l-.733-.184c.08-.329-.117-.727-.442-.888-.326-.162-.772-.102-1.083.146-.305.243-.499.665-.505 1.1-.004.316.09.598.258.773.106.11.254.17.392.177v-1.42h.755v2.722H1.49v-.534a1.343 1.343 0 0 1-.934-.414c-.311-.323-.478-.79-.471-1.314.01-.67.305-1.301.792-1.689zm12.338-5.561l-.022 3.582H7.11c-.006-1.213-.394-2.832 1.036-3.257.45-.134 5.067-.242 5.068-.325z"
      />
    </SvgIcon>
  )
);
