import {
  FSCourseTeachersGuide,
  getCourseTeachersGuideCollectionRef
} from '../../collections/Courses/TeachersGuide';
import { useState, useEffect } from 'react';
import { collectionData, docData } from 'rxfire/firestore';
import { concat, map } from 'rxjs/operators';
import { combineLatest, forkJoin } from 'rxjs';
import {
  FSCourseTrainingCourse,
  FSCourse,
  getCoursesCollectionRef
} from '../../collections/Courses';
import { FSAssignment } from '../../collections/TrainingCourses/Assignments';
import { FSTrainingCourse } from '../../collections/TrainingCourses';
import { FSSubject } from '../../collections/Subjects';
import { User } from '../../../components/Auth/AuthProvider';
import {
  CourseStatus,
  FSInstitutionCourse,
  getInstitutionsCoursesCollectionRef,
  getCourseCustomExercisesCollectionRef
} from '../../collections/Institutions/Courses';
import { isCourseInstance, isCourseTemplate } from './helpers';
import { FSExercise } from '../../collections/Exercises';

export function useCourseTeachersGuide(courseId: string) {
  const [teachersGuide, setTeachersGuide] = useState<
    FSCourseTeachersGuide[] | undefined
  >(undefined);
  useEffect(() => {
    const subscription = collectionData<FSCourseTeachersGuide>(
      getCourseTeachersGuideCollectionRef(courseId),
      'id'
    )
      .pipe(concat())
      .subscribe(teachersGuide => setTeachersGuide(teachersGuide));
    return () => subscription.unsubscribe();
  }, [courseId]);
  return teachersGuide;
}

export function useCourseTrainingCourseAssignments(
  courseTrainingCourse: FSCourseTrainingCourse
) {
  const [exercises, setExercises] = useState<FSAssignment[] | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      const exs = await (await Promise.all(
        courseTrainingCourse.requiredAssignmentRefs.map(ref => ref.get())
      )).map(snap => {
        return {
          id: snap.id,
          ...snap.data()
        } as FSAssignment;
      });
      setExercises(exs);
    })();
  }, []);
  return exercises;
}

export function useReferencedTrainingCourses(course: FSCourse) {
  const [trainingCourses, setTrainingCourses] = useState<
    FSTrainingCourse[] | undefined
  >(undefined);

  useEffect(() => {
    if (!isCourseInstance(course) && !isCourseTemplate(course)) {
      return;
    }
    (async () => {
      const refs = course.trainingCourses.map(ctc => ctc.trainingCourseRef);
      const tcs = await (await Promise.all(refs.map(ref => ref.get()))).map(
        snap => {
          return {
            id: snap.id,
            ...snap.data()
          } as FSTrainingCourse;
        }
      );
      setTrainingCourses(tcs);
    })();
  }, []);
  return trainingCourses;
}

export function useReferencedSubjects(course: FSCourse) {
  const [subjects, setSubjects] = useState<FSSubject[] | undefined>(undefined);
  //const refs = course.subjects.map(ctc => ctc);
  const refs = course.subjects;
  useEffect(() => {
    (async () => {
      const tcs = await (await Promise.all(refs.map(ref => ref.get()))).map(
        snap => {
          return {
            id: snap.id,
            ...snap.data()
          } as FSSubject;
        }
      );
      setSubjects(tcs);
    })();
  }, []);
  return subjects;
}

export function useCourse(courseId: string) {
  const [course, setCourse] = useState<FSCourse | undefined>(undefined);
  useEffect(() => {
    const subscription = docData<FSCourse>(
      getCoursesCollectionRef().doc(courseId),
      'id'
    )
      .pipe()
      .subscribe(course => {
        if (isCourseTemplate(course)) {
          setCourse(course);
        }
      });
    return () => subscription.unsubscribe();
  }, [courseId]);
  return course;
}

export function useCourses(publishedOnly: boolean) {
  const [courses, setCourses] = useState<FSCourse[] | undefined>(undefined);
  useEffect(() => {
    const collectionRef = publishedOnly
      ? getCoursesCollectionRef().where(
          'courseTemplateStatus',
          '==',
          'published'
        )
      : getCoursesCollectionRef();
    const subscription = collectionData<FSCourse>(collectionRef, 'id')
      .pipe(concat())
      .subscribe(courses => setCourses(courses.map(course => course)));
    return () => subscription.unsubscribe();
  }, []);

  return courses;
}

export function useInstitutionCourseCustomExercises(
  institutionId: string,
  courseId: string
) {
  const [exercises, setExercises] = useState<FSExercise[] | undefined>(
    undefined
  );
  useEffect(() => {
    const subscription = collectionData<FSExercise>(
      getCourseCustomExercisesCollectionRef(institutionId, courseId),
      'id'
    )
      .pipe(
        map(docs =>
          docs.map(d => ({
            ...d,
            documentRef: getCourseCustomExercisesCollectionRef(
              institutionId,
              courseId
            ).doc(d.id)
          }))
        )
      )
      .subscribe(exercises => setExercises(exercises));
    return () => subscription.unsubscribe();
  }, [institutionId, courseId]);
  return exercises;
}

export function useMyCourse(courseId: string, user: User) {
  const [course, setCourse] = useState<FSInstitutionCourse | undefined>(
    undefined
  );
  const { institutionId } = user;
  useEffect(() => {
    const subscription = docData<FSInstitutionCourse>(
      getInstitutionsCoursesCollectionRef(institutionId).doc(courseId),
      'id'
    )
      .pipe()
      .subscribe(course => {
        if (isCourseInstance(course)) {
          setCourse(course);
        }
      });
    return () => subscription.unsubscribe();
  }, [courseId, institutionId]);
  return course;
}

export function useCoursesFromUnicGroups(
  user: User,
  unicGroups: string[],
  specificGroup: boolean,
  status: CourseStatus = 'published',
  forUser: boolean = false
) {
  const [myCourses, setMyCourses] = useState<FSInstitutionCourse[] | undefined>(
    undefined
  );

  const { institutionId } = user;
  // const { unicGroups } = institutionUser;
  const isStudent = user.isRole('student');
  const isPermittedTeacher = user.isPerm('teacher');
  const uid = user.firebaseUser.uid;

  useEffect(() => {
    (async () => {
      if ((isStudent && Array.isArray(unicGroups)) || specificGroup) {
        const subscription = forkJoin(
          unicGroups.map(async unicGroup => {
            let query = getInstitutionsCoursesCollectionRef(institutionId)
              .where('unicGroups', 'array-contains', unicGroup)
              .where('status', '==', status);

            if (forUser) {
              query = query.where('ownerUID', '==', uid);
            }

            return collectionData<FSInstitutionCourse>(query, 'id');
          })
        ).subscribe(obs$ =>
          combineLatest(...obs$).subscribe(myCourses =>
            setMyCourses(myCourses.flat())
          )
        );
        return () => subscription.unsubscribe();
      } else if (isPermittedTeacher) {
        const query = getInstitutionsCoursesCollectionRef(institutionId)
          .where('ownerUID', '==', uid)
          .where('status', '==', status);

        const subscription = collectionData<FSInstitutionCourse>(query, 'id')
          .pipe(concat())
          .subscribe(myCourses => setMyCourses(myCourses));
        return () => subscription.unsubscribe();
      }
    })();
  }, [
    institutionId,
    unicGroups,
    status,
    isStudent,
    isPermittedTeacher,
    uid,
    specificGroup,
    forUser
  ]);
  return myCourses;
}
