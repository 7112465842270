import FirestoreBaseModel from '../../../FirestoreBase';
import { getInstitutionsCollectionRef } from '..';

export const USERS_COLLECTION = 'users';
export interface FSInstututionUser extends FirestoreBaseModel {
  unicGroups: Array<string>;
  unicFunctionDescription: number;
}

export function getInstitutionsUsersCollectionRef(institutionId: string) {
  return getInstitutionsCollectionRef()
    .doc(institutionId)
    .collection(USERS_COLLECTION);
}
