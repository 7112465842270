import styled from 'styled-components';
import { StorageMedia } from '../StorageMedia';
import React, { useState, useContext } from 'react';
import { Modal } from '@material-ui/core';
import { StorageReference } from '../../firestore/collections/Storage';

const Image = styled(StorageMedia)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

type Options = {
  storageReference?: StorageReference | undefined;
};

type ImageDialogOptions = {
  options: Options;
  setOptions: (options: Options) => void;
};

const ImageDialogContext = React.createContext<ImageDialogOptions>({
  options: {},
  setOptions: () => null
});

type ImageDialogProviderProps = {
  children: React.ReactNode;
};

export function ImageDialogProvider(props: ImageDialogProviderProps) {
  const context = useContext(ImageDialogContext);
  const [options, setOptions] = useState<Options>(() => context.options);

  function onCancel() {
    setOptions({
      storageReference: undefined
    });
  }

  return (
    <>
      <ImageDialogContext.Provider value={{ setOptions, options }}>
        {props.children}
      </ImageDialogContext.Provider>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={typeof options.storageReference !== 'undefined'}
        onClose={onCancel}
        onClick={onCancel}
      >
        <div
          style={{
            outline: 'none',
            display: 'block',
            marginLeft: '5%',
            marginTop: '5%',
            width: '90%',
            height: '90%'
          }}
        >
          {options.storageReference && (
            <Image storageReference={options.storageReference} />
          )}
        </div>
      </Modal>
    </>
  );
}
export function useImageDialog() {
  const context = useContext(ImageDialogContext);

  function show(storageReference: StorageReference) {
    context.setOptions({
      storageReference: storageReference
    });
  }

  return show;
}
