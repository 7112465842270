import React from 'react';
import styled from 'styled-components';
import { StorageReference } from '../firestore/collections/Storage';
import { StorageMedia } from './StorageMedia';
import { Hidden } from '@material-ui/core';

const CoverFrame = styled.div<{
  backgroundColorLeft: string;
  backgroundColorRight: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${props => props.theme.breakpoints.up('lg')} {
    height: 300px;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    height: 250px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    height: 200px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    height: 150px;
  }
  ${props => props.theme.breakpoints.down('xs')} {
    height: 75px;
  }
  overflow: hidden;
  :before {
    content: '';
    width: 50%;
    left: 50%;
    height: 100%;
    background: ${props => props.backgroundColorLeft};
  }
  :after {
    content: '';
    width: 50%;
    left: 0;
    height: 100%;
    background: ${props => props.backgroundColorRight};
  }
`;

const StyledImage = styled.img`
  height: 100%;
  display: flex;
`;
const StyledStorageMedia = styled(StorageMedia)`
  height: 100%;
  display: flex;
`;

interface HeaderCoverProps {
  coverImageRef?: StorageReference | string;
  color?: string;
  title?: string;
  subtitle?: string;
  backgroundColorLeft?: string;
  backgroundColorRight?: string;
}

function renderImage(coverRef: StorageReference | string | undefined) {
  if (typeof coverRef === 'undefined') {
    return null;
  } else if (typeof coverRef === 'string') {
    return <StyledImage src={coverRef} />;
  } else {
    return <StyledStorageMedia storageReference={coverRef} />;
  }
}
export default function HeaderCover(props: HeaderCoverProps) {
  const { coverImageRef, backgroundColorLeft, backgroundColorRight } = props;

  return (
    <Hidden smDown>
      <CoverFrame
        backgroundColorLeft={
          backgroundColorLeft ? backgroundColorLeft : 'white'
        }
        backgroundColorRight={
          backgroundColorRight ? backgroundColorRight : 'white'
        }
      >
        {renderImage(coverImageRef)}
      </CoverFrame>
    </Hidden>
  );
}
