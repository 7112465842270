import FirestoreBaseModel from '../../../FirestoreBase';
import { getInstitutionsCollectionRef } from '..';

export const UNIC_GROUPS_COLLECTION = 'unicGroups';
export interface FSUnicGroup extends FirestoreBaseModel {
  unicGroupType: string;
  unicName: string;
  unicGroupId: string;
  sanitizedGroupName: string;
}

export function getInstitutionsUnicGroupsCollectionRef(institutionId: string) {
  return getInstitutionsCollectionRef()
    .doc(institutionId)
    .collection(UNIC_GROUPS_COLLECTION);
}
