import React, { useState } from 'react';
import { Drawer, Grid, Typography, Button, Hidden } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { get as _get } from 'lodash';
import styled from 'styled-components';
import { Content } from './Body';
import Illustration from '../assets/illustrations/pcthumbsup_small.png';
import { Text } from './TextLink';
import { useTranslation } from 'react-i18next';

const DrawerBg = styled.div`
  background-color: ${props => props.theme.extraPalette.sand[900]};
`;

const ImgStyled = styled.img`
  width: 100%;
  height: 100%;
  max-width: 120px;
  object-fit: contain;
`;

const StyledA = styled.a`
  text-decoration: none;
  color: inherit;
`;

interface CookieDrawerProps {
  allowCookies: () => void;
}

function CookieDrawer(props: CookieDrawerProps) {
  const [t] = useTranslation();
  function closeDrawer() {
    props.allowCookies();
  }

  return (
    <Drawer anchor="bottom" open={true} variant="permanent">
      <DrawerBg>
        <Content paddingTop={2} paddingBottom={2}>
          <Grid container direction="row" spacing={4}>
            <Grid item xs>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6" color="secondary" paragraph>
                    {t('cookies.title')}
                  </Typography>
                  <Typography variant="caption">{t('cookies.body')}</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => closeDrawer()} variant="contained">
                    {t('cookies.button')}
                  </Button>
                </Grid>
                <Grid item>
                  <Typography color="secondary" variant="caption" paragraph>
                    <StyledA href={t('cookies.link')} target="_blank">
                      →&nbsp;
                      <Text color="secondary" variant="caption">
                        {t('cookies.read_more')}
                      </Text>
                    </StyledA>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={4}>
                <ImgStyled src={Illustration} />
              </Grid>
            </Hidden>
          </Grid>
        </Content>
      </DrawerBg>
    </Drawer>
  );
}

const ALLOW_COOKIES = 'allow_cookies';

interface CookieDrawerProps {
  allowCookies: () => void;
}

export default () => {
  const [cookies, setCookie] = useCookies(['gyldendal-teknologi']);
  const [cookieConsent, setCookieConsent] = useState(
    _get(cookies, ALLOW_COOKIES) === 'true'
  );

  function allowCookies() {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    setCookie(ALLOW_COOKIES, true, { path: '/', expires: nextYear });
    setCookieConsent(true);
  }

  return <>{!cookieConsent && <CookieDrawer allowCookies={allowCookies} />}</>;
};
