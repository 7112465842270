import { Drawer, Fab, Typography, withWidth } from '@material-ui/core';
import { WithWidthProps } from '@material-ui/core/withWidth';
import Back from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
  Link,
  matchPath,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import { isNull } from 'lodash';
import styled from 'styled-components';
import { User, useIsSignedIn } from '../../Auth/AuthProvider';
import { GoBackButtonProps } from '../../GoBackButton';
import SideMenuButton from '../../SideMenuButton';
import TFLogoNoText from '../../TFLogoNoText';

const LogoLink = styled(Link)`
  text-decoration: none !important;
`;
const DrawerFrame = styled.div`
  width: 304px;
  height: 100%;
`;
const DrawerHeader = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
`;

const DrawerContent = styled.div<{ withoutPadding?: boolean }>`
  padding: ${props =>
    props.withoutPadding ? '0 0 0 0' : '10px 20px 10px 20px'};
  height: calc(100% - 65px - 65px);
  overflow: auto;
`;
const BackButton = styled(Fab)`
  color: grey;
  background-color: transparent !important;
  box-shadow: none !important;
`;
const DrawerTitle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export interface DrawerMenuLinkItem {
  title: string;
  location: string;
  showForUser?: (user: User) => boolean;
}
export interface DrawerMenuProps extends RouteComponentProps, WithWidthProps {
  goBackButton?: GoBackButtonProps;
  actionMenu?: JSX.Element;
  drawerTitle?: string;
  headerMenuItems?: DrawerMenuLinkItem[];
  user?: User;
  open: boolean;
  onClose: () => void;
  type: 'main' | 'phase';
  children?: React.ReactNode;
}

const DrawerMenu = withRouter(function DrawerMenu(props: DrawerMenuProps) {
  const { headerMenuItems, user } = props;
  const isSignedIn = useIsSignedIn();
  function goBack(goBackProps: GoBackButtonProps | undefined) {
    if (goBackProps) {
      props.history.push(goBackProps.location);
    }
    props.onClose();
  }

  return (
    <>
      <Drawer open={props.open} onClose={() => props.onClose()}>
        <DrawerFrame>
          <DrawerHeader>
            {props.drawerTitle ? (
              <DrawerTitle>
                <Typography variant="subtitle1" color="secondary">
                  {props.drawerTitle}
                </Typography>
              </DrawerTitle>
            ) : null}
            {props.goBackButton ? (
              <BackButton onClick={() => goBack(props.goBackButton)}>
                <Back />
              </BackButton>
            ) : null}
          </DrawerHeader>
          {props.type !== 'main' || !headerMenuItems ? null : (
            <DrawerContent>
              {headerMenuItems
                ? headerMenuItems.map((item, i) => {
                    return (
                      <SideMenuButton
                        key={i}
                        to={item.location}
                        onClick={props.onClose}
                        selected={
                          !isNull(
                            matchPath(`${props.location.pathname}`, {
                              path: `${item.location}`,
                              exact: false
                            })
                          )
                        }
                      >
                        {item.title}
                      </SideMenuButton>
                    );
                  })
                : null}
            </DrawerContent>
          )}
          {props.type === 'phase' ? (
            <DrawerContent withoutPadding={true}>
              {props.children}
            </DrawerContent>
          ) : null}
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              height: 65,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '100%'
            }}
          >
            <LogoLink to={'/'}>
              <TFLogoNoText />
            </LogoLink>
          </div>
        </DrawerFrame>
      </Drawer>
    </>
  );
});

export default withWidth()(DrawerMenu);
