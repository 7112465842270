import React from 'react';
import { StorageReference } from '../firestore/collections/Storage';
import ResponsivePlayer from './ResponsivePlayer';
import { useStorageUrlWithCache } from '../firestore/models/Storage/hooks';
interface StorageMediaProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  storageReference: StorageReference;
  width?: number;
  children?: (url: string | undefined) => JSX.Element;
  renderLoading?: () => JSX.Element;
  onImageClick?: (id: any) => void;
}

function generateSrcSet(storageURL: string) {
  const tmbBaseURL = process.env.REACT_APP_TMB;
  if (!tmbBaseURL) {
    return;
  }
  const sizes = [600, 960, 1280, 1920];

  const srcSetStrings = sizes.map(
    size =>
      `${tmbBaseURL}img/width=${size}/r/${encodeURIComponent(
        storageURL
      )} ${size}w`
  );
  return srcSetStrings.join(',');
}

function generateURL(
  storage: {
    url: string;
    mimetype: string;
    storageUrl: string;
  },
  width: number | undefined,
  type?: 'image' | 'video'
) {
  const tmbBaseURL = process.env.REACT_APP_TMB;
  if (!tmbBaseURL) {
    return storage.url;
  }

  const prefix = type === 'video' ? 'video' : 'img';

  // Note that video sizes are always 720p (per decision)

  return `${tmbBaseURL}${prefix}/width=${width}/r/${encodeURIComponent(
    storage.storageUrl
  )}`;
}

export function StorageMedia(props: StorageMediaProps) {
  const { storageReference, ...otherProps } = props;
  const storage = useStorageUrlWithCache(storageReference);
  if (!storage) {
    // Handle render-props
    const cb = props.renderLoading || props.children;
    if (cb) {
      return cb(undefined);
    }

    return null;
  }

  const defaultWidth = 600;

  // Default URL
  const mediaType =
    storage.mimetype.indexOf('video') !== -1 ? 'video' : 'image';
  let url = generateURL(storage, props.width || defaultWidth, mediaType);
  let srcSet = generateSrcSet(storage.storageUrl);

  if (typeof props.children === 'function') {
    // Tell the renderProp about URL
    return props.children(url);
  }

  if (mediaType === 'video') {
    return <ResponsivePlayer url={url} />;
  }

  return (
    <img
      onClick={props.onImageClick}
      style={{ cursor: props.onImageClick ? 'pointer' : 'auto' }}
      src={url}
      srcSet={srcSet}
      {...otherProps}
      alt={'Billede tmb'}
    />
  );
}
