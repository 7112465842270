import React, { useEffect } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router';
import { Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LinearProgress } from '@material-ui/core';
import {
  useAuthIsLoading,
  useCurrentUser,
  useIsSignedIn
} from '../components/Auth/AuthProvider';
import MyClasses from './MyClasses';
import ProtectedRoute from '../components/Auth/ProtectedRoute';
import { include, reverse } from 'named-urls';
import ReactGA, { FieldsObject } from 'react-ga';
import CookieConsent from '../components/CookieConsent';
import { CookiesProvider } from 'react-cookie';
import { createBrowserHistory } from 'history';
import { RediaScrollMemory } from '../components/RediaScrollMemory';
import { NoLicense } from './NoLicense';
import { NoAccess } from './NoAccess';
const history = createBrowserHistory(); // Better to have our own

export const EDITOR_ADMIN_PATH = '/editor-admin';
export const HOME_PATH = '/home';
export const NO_LICENSE_PATH = '/no-license';
export const NO_ACCESS_PATH = '/no-access';

export const LOGIN_PATH = '/login';
export const LOGIN_PATH_UNIC = '/login/unic';
export const LOGIN_PATH_GOOGLE = '/login/google';
export const COURSES_PATH = '/courses';
export const MY_COURSES_PATH = '/courses/my';
export const TEMPLATE_COURSES_PATH = '/courses/templates';
export const TRAINING_COURSES_PATH = '/training-courses';
export const MY_CLASSES_PATH = '/my-classes';
export const TEACHERS_RESOURCES_PATH = '/teachers-resources';
export const THEME_PATH = '/theme';
export const LOGBOOK_PATH = '/logbook';
export const ADMIN_USER_PRESENCE_PATH = '/editor-admin/user-presence';
export const ADMIN_INSTITUTIONS_PATH = '/editor-admin/institutions';

const CourseRoutePaths = {
  introduction: 'introduction',
  courseOverview: 'course_overview',
  trainingCourses: 'training-courses',
  teachersGuide: 'teachers-guide',
  classesGroups: 'classes-groups',
  designPhases: 'design-phases'
};

export const RoutePaths = {
  editorAdmin: '/editor-admin',
  home: '/home',
  login: '/login',
  nolicense: '/no-license',
  noaccess: '/no-access',
  signout: '/login/signout',
  courses: include('/courses', {
    show: '',
    my: include('my', {
      overview: '',
      detail: include(':courseId', {
        show: '',
        ...CourseRoutePaths
      })
    }),
    templates: include('templates', {
      overview: '',
      detail: include(':courseId', {
        show: '',
        ...CourseRoutePaths
      })
    })
  }),
  trainingCourses: include('/training-courses/', {
    overview: '',
    detail: include(':trainingCourseId', {
      overview: 'overview',
      assignments: 'assignments',
      documentation: 'documentation'
    })
  }),

  myClasses: include('/my-classes/', {
    overview: '',
    detail: include(':classId', {
      overview: ''
    })
  }),
  teachersResources: '/teachers-resources',
  theme: '/theme',
  logBook: '/logbook'
};

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {}
) => {
  const trackPage = (page: string) => {
    if (process.env.REACT_APP_GA_ENABLED === '1') {
      ReactGA.set({ page, ...options });
      ReactGA.pageview(page);
    }
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};

export const Load = (importer: any, inProps = {}, render = null) => {
  return Loadable({
    loader: importer,
    loading: () => <LinearProgress />,
    render:
      render ||
      ((loaded, props) => {
        // @ts-ignore:
        let Component = loaded.default;
        return <Component {...props} {...inProps} />;
      })
  });
};

const Home = Load(() => import('./Home'));
const Login = Load(() => import('./Login'));
const _404 = Load(() => import('./_404'));
const EditorAdmin = Load(() => import('./EditorAdmin'));
const Courses = Load(() => import('./Courses'));
const TeachersResources = Load(() => import('./TeachersResources'));
const TrainingCourses = Load(() => import('./TrainingCourses'));
const Theme = Load(() => import('./Theme'));
const Print = Load(() => import('./Print'));

function App() {
  const isAuthLoading = useAuthIsLoading();
  const user = useCurrentUser();
  const isSignedIn = useIsSignedIn();

  if (isAuthLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Route
        path={'/'}
        render={props => {
          if (
            ![
              reverse(RoutePaths.nolicense),
              reverse(RoutePaths.login),
              reverse(RoutePaths.home),
              reverse(RoutePaths.signout)
            ].includes(props.location.pathname)
          )
            if (isSignedIn && user) {
              if (!user.hasLicense) {
                return <Redirect to={reverse(RoutePaths.nolicense)} />;
              }
            }
          return null;
        }}
      />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            if (isSignedIn && user && user.isRole('student')) {
              return <Redirect to={reverse(RoutePaths.courses.my.overview)} />;
            } else {
              return <Redirect to={reverse(RoutePaths.home)} />;
            }
          }}
        />
        <Route path={RoutePaths.login} component={Login} />
        <Route path={RoutePaths.nolicense} component={NoLicense} />
        <Route path={RoutePaths.noaccess} component={NoAccess} />
        <Route exact path={RoutePaths.home} component={Home} />
        <Route path={RoutePaths.courses.toString()} component={Courses} />
        <Route
          path={RoutePaths.trainingCourses.overview}
          component={TrainingCourses}
        />
        <ProtectedRoute
          onAuth={user => user.isPerm('student')}
          path={RoutePaths.trainingCourses.overview}
          component={TrainingCourses}
        />
        <ProtectedRoute
          onAuth={user => user.isRole('teacher')}
          path={RoutePaths.myClasses.toString()}
          component={MyClasses}
        />
        <ProtectedRoute
          onAuth={user => user.isRole('teacher')}
          path={RoutePaths.myClasses.toString()}
          component={Courses}
        />

        <Route
          exact
          path={`${RoutePaths.teachersResources}`}
          render={() => (
            <Redirect to={`${RoutePaths.teachersResources}/what`} />
          )}
        />
        <Route
          path={RoutePaths.teachersResources}
          component={TeachersResources}
        />
        <ProtectedRoute
          onAuth={user => user.isPerm('admin')}
          path={RoutePaths.editorAdmin}
          component={EditorAdmin}
        />
        <ProtectedRoute
          path={RoutePaths.theme}
          component={Theme}
          onAuth={() => process.env.REACT_APP_ENV !== 'production'}
        />
        <ProtectedRoute
          path={'/print'}
          component={Print}
          onAuth={user => user.isPerm('teacher')}
        />
        <Route path={'*'} component={_404} />
      </Switch>
    </>
  );
}

export default () => {
  return (
    <Router history={history}>
      <CookiesProvider>
        <CookieConsent />
      </CookiesProvider>
      <RediaScrollMemory blackList={['/view', '/select-classes']} />
      <Route component={withTracker(App)}></Route>
    </Router>
  );
};
