import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Options = {
  open: boolean;
  title: string | null;
  confirmButtonText: string;
  body: string | React.ReactNode;
  alert: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

type ConformDialogOptions = {
  options: Options;
  setOptions: (options: Options) => void;
};

const ConfirmDialogContext = React.createContext<ConformDialogOptions>({
  options: {
    open: false,
    title: '',
    body: '',
    confirmButtonText: '',
    alert: false,
    onSubmit: () => null,
    onCancel: () => null
  },
  setOptions: () => null
});

type ConfirmDialogProviderProps = {
  children: React.ReactNode;
};

export function ConfirmDialogProvider(props: ConfirmDialogProviderProps) {
  const context = useContext(ConfirmDialogContext);
  const [options, setOptions] = useState<Options>(() => context.options);
  const [t] = useTranslation();
  return (
    <>
      <ConfirmDialogContext.Provider value={{ setOptions, options }}>
        {props.children}
      </ConfirmDialogContext.Provider>
      <Dialog
        open={options.open}
        onClose={options.onCancel}
        style={{ padding: 10 }}
      >
        <DialogContent>
          {options.title ? (
            <DialogTitle style={{ marginBottom: 20 }}>
              {options.title}
            </DialogTitle>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )}
          {typeof options.body === 'string' ? (
            <DialogContentText>{options.body}</DialogContentText>
          ) : (
            options.body
          )}
        </DialogContent>
        <DialogActions>
          {options.alert || (
            <Button
              style={{ marginRight: 30 }}
              onClick={options.onCancel}
              color="default"
              variant="outlined"
            >
              {t('cancel')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={options.onSubmit}
            color="secondary"
            autoFocus
          >
            {t(options.alert ? 'ok' : options.confirmButtonText)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export function useConfirmDialog() {
  const context = useContext(ConfirmDialogContext);

  function show(
    title: string | null,
    body: string | React.ReactNode,
    confirmButtonText: string = 'Bekræft',
    alert: boolean = false
  ): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      context.setOptions({
        title,
        body,
        confirmButtonText,
        alert,
        open: true,
        onSubmit: () => resolve(true),
        onCancel: () => resolve(false)
      });
    });

    promise.finally(() => {
      context.setOptions({
        title: '',
        body: null,
        confirmButtonText: '',
        alert: false,
        open: false,
        onSubmit: () => null,
        onCancel: () => null
      });
    });

    return promise;
  }
  return show;
}
