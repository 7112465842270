import React, { ReactNode } from 'react';
import styled from 'styled-components';
import LogIcon from './Header/components/LogIcon';
import { generatePath, RouteComponentProps } from 'react-router';

const initSize = 250;
const initIconCircleSize = 64;
const initIconSize = 46;

export const PhaseIconCircle = styled(({ size, ...otherProps }) => (
  <div {...otherProps} />
))<{ size: number }>`
  width: ${props => (props.size ? props.size : initIconSize)}px;
  height: ${props => (props.size ? props.size : initIconSize)}px;
  background-color: ${props => props.theme.extraPalette.sand[700]};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${props =>
    props.theme.shadows[4]}; // 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
  border-radius: ${props => (props.size ? props.size / 2 : initIconSize / 2)}px;
`;

const IconOnPhaseCircle = styled(
  ({
    n,
    size,
    iconCircleSize,
    numChildren,
    iconScale,
    includeLog,
    ...otherProps
  }) => <PhaseIconCircle {...otherProps} />
)<{ n: number; size: number; numChildren: number }>`
  width: ${props => props.iconCircleSize}px;
  height: ${props => props.iconCircleSize}px;
  border-radius: ${props => props.iconCircleSize / 2}px;
  background-color: ${props => props.theme.extraPalette.grey[50]};
  transform: rotate(${props => props.n * (360 / props.numChildren) - 90}deg)
    translate(${props => props.size / 2}px)
    rotate(${props => ((360 / props.numChildren) * props.n - 90) * -1}deg);
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -${props => props.iconCircleSize / 2}px;
`;

const CirleShape = styled.div<{ size: number; margin: number }>`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  border: 7px solid #494e4e;
`;

const WrapperStyled = styled.div<{ size: number; padding: number }>`
  padding: ${props => props.padding}px;
`;

const CenterContentWrapper = styled.div<{ size: number }>`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  position: relative;
  vertical-align: middle;
  display: table-cell;
  padding-left: 20%;
  padding-right: 20%;
`;
const MiddleLogButton = styled.div`
  position: absolute;
`;
const ScaledLogIcon = styled(({ scale, ...otherProps }) => (
  <LogIcon {...otherProps} />
))<{ scale: number }>`
  && {
    transform: scale(${props => props.scale});
  }
`;

interface PhaseCircleProps extends RouteComponentProps {
  children: ReactNode;
  size: number;
  getCenterContent?: Function;
  includeLog?: boolean;
  phaseList?: string[];
  onCloseDrawer?: () => void;
}

export default (props: PhaseCircleProps) => {
  const numChildren = React.Children.count(props.children);

  function navigateTo(to: string) {
    if (to !== 'cancel') {
      props.history.push(
        generatePath(props.match.path, {
          ...props.match.params,
          designPhase: to
        })
      );
      if (props.onCloseDrawer) props.onCloseDrawer();
    }
  }

  const iconCircleSize = Math.round(
    (props.size / initSize) * initIconCircleSize
  );
  const boxSize = props.size + iconCircleSize * 1;
  const iconScale = (props.size / initSize) * 1.5;

  return !props.children ? null : (
    <WrapperStyled size={boxSize} padding={iconCircleSize / 2}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <CirleShape margin={iconCircleSize / 2} size={props.size}>
          {React.Children.map(props.children, (c, i) => (
            <IconOnPhaseCircle
              onClick={() =>
                navigateTo(
                  props.phaseList && props.includeLog
                    ? props.phaseList[i]
                    : 'cancel'
                )
              }
              size={props.size}
              iconCircleSize={iconCircleSize}
              n={i}
              numChildren={numChildren}
              iconScale={iconScale}
            >
              {c}
            </IconOnPhaseCircle>
          ))}
          {props.getCenterContent && (
            <CenterContentWrapper size={props.size}>
              {props.getCenterContent()}
            </CenterContentWrapper>
          )}
        </CirleShape>
        {props.includeLog ? (
          <MiddleLogButton
            onClick={() => navigateTo(props.includeLog ? 'log' : 'cancel')}
          >
            <IconOnPhaseCircle
              size={props.size}
              iconCircleSize={iconCircleSize}
              numChildren={numChildren}
              iconScale={iconScale}
            >
              <ScaledLogIcon fontSize="large" withColor />
            </IconOnPhaseCircle>
          </MiddleLogButton>
        ) : null}
      </div>
    </WrapperStyled>
  );
};
