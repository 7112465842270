// export enum DesignPhases {
//   challenge = 'challenge',
//   research = 'research',
//   ideas = 'ideas',
//   prototype = 'prototype',
//   presentation = 'presentation',
//   reflection = 'reflection'
// }

export const DesignPhases = {
  challenge: 'challenge',
  research: 'research',
  ideas: 'ideas',
  prototype: 'prototype',
  presentation: 'presentation',
  reflection: 'reflection'
};

export type DesignPhase = keyof typeof DesignPhases;

export const designPhases = [
  'challenge',
  'research',
  'ideas',
  'prototype',
  'presentation',
  'reflection'
];

// export type DesignPhase =
//   | "challenge"
//   | "research"
//   | "ideas"
//   | "prototype"
//   | "presentation"
//   | "reflection";
