import { Grid, Typography } from '@material-ui/core';
import { reverse } from 'named-urls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import class_banner_icon from '../../../assets/icons/class_banner_icon.png';
import MainActionMenu from '../../../components/ActionMenu/MainActionMenu';
import { useCurrentUser } from '../../../components/Auth/AuthProvider';
import Body, { Content } from '../../../components/Body';
import ClassHeaderCover from '../../../components/ClassHeaderCover';
import CoursesGrid from '../../../components/CoursesGrid';
import MainMenu from '../../../components/Header/components/MainMenu';
import Header from '../../../components/Header/Header';
import { useCoursesFromUnicGroups } from '../../../firestore/models/Course/hooks';
import { useInstitution } from '../../../firestore/models/Institution/hooks';
import { sand } from '../../../style/palettes';
import { RoutePaths } from '../../AppRouter';

const CoursesSection = styled(Grid)`
  && {
    padding-top: 96px !important;
    width: 100%;
  }
`;

const CourseSectionHeader = styled(Typography)`
  && {
    margin-bottom: ${props => props.theme.spacing(3)}px;
  }
`;

const NoContentBody = styled(Typography)`
  padding-bottom: ${props => props.theme.spacing(4)}px;
`;

interface ClassParams {
  classId: string;
}

interface CoursesProps {
  unicGroupIds: string[];
}
function ActiveCourses(props: CoursesProps) {
  const [t] = useTranslation();
  const user = useCurrentUser();
  const activeCourses = useCoursesFromUnicGroups(
    user,
    props.unicGroupIds,
    true
  );

  if (!activeCourses) {
    return null;
  }

  return (
    <>
      <CourseSectionHeader variant="h6" paragraph gutterBottom>
        {t('my_courses.active_courses')}
      </CourseSectionHeader>
      {activeCourses.length === 0 && (
        <NoContentBody variant="body1">
          {user.isRole('student')
            ? t('my_courses.no_active_courses_student')
            : t('my_courses.no_active_courses')}
        </NoContentBody>
      )}

      <CoursesGrid
        getLink={courseId =>
          reverse(RoutePaths.courses.my.detail.show, { courseId })
        }
        courses={activeCourses}
      />
    </>
  );
}

function EndedCourses(props: CoursesProps) {
  const user = useCurrentUser();
  const [t] = useTranslation();

  const endedCourses = useCoursesFromUnicGroups(
    user,
    props.unicGroupIds,
    true,
    'ended'
  );
  if (!endedCourses) {
    return null;
  }
  return (
    <>
      <CourseSectionHeader variant="h6" paragraph>
        {t('my_courses.completed_courses')}
      </CourseSectionHeader>
      {endedCourses.length === 0 && (
        <NoContentBody variant="body1" paragraph>
          {t('my_courses.no_completed_courses')}
        </NoContentBody>
      )}
      <CoursesGrid
        getLink={courseId =>
          reverse(RoutePaths.courses.my.detail.show, { courseId })
        }
        courses={endedCourses}
      />
    </>
  );
}

function MyDraftCourses(props: CoursesProps) {
  const [t] = useTranslation();
  const user = useCurrentUser();
  const myDraftCourses = useCoursesFromUnicGroups(
    user,
    props.unicGroupIds,
    true,
    'draft',
    true
  );

  if (!myDraftCourses) {
    return null;
  }

  return (
    <>
      <CourseSectionHeader variant="h6" paragraph>
        {t('my_courses.draft_courses')}
      </CourseSectionHeader>
      {myDraftCourses.length === 0 && (
        <Typography variant="body1">
          {t('my_courses.no_saved_courses')}
        </Typography>
      )}

      <CoursesGrid
        getLink={courseId =>
          reverse(RoutePaths.courses.my.detail.show, { courseId })
        }
        courses={myDraftCourses}
      />
    </>
  );
}

export default function MyClass(props: RouteComponentProps<ClassParams>) {
  const [t] = useTranslation();
  const user = useCurrentUser();
  const institution = useInstitution(user.institutionId);

  const schoolName = institution ? institution.unicName : '';
  let unicGroupIds = [] as string[];
  let classId = props.match.params.classId;
  unicGroupIds.push(classId);

  return (
    <>
      <Header>
        <MainMenu
          drawerTitle={t('drawer_titles.class')}
          actionMenu={<MainActionMenu />}
        />
      </Header>
      <ClassHeaderCover
        color={sand[500]}
        title={classId}
        subtitle={schoolName}
        coverImageRef={class_banner_icon}
      />
      <Body>
        <Content>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={8}
          >
            <CoursesSection item>
              <ActiveCourses unicGroupIds={unicGroupIds} />
            </CoursesSection>
            <CoursesSection item>
              <EndedCourses unicGroupIds={unicGroupIds} />
            </CoursesSection>
            <CoursesSection item>
              <MyDraftCourses unicGroupIds={unicGroupIds} />
            </CoursesSection>
          </Grid>
        </Content>
      </Body>
    </>
  );
}
