import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { StorageReference } from '../firestore/collections/Storage';
import { MaxWidthCenteredContainer } from './Body';

const CoverFrame = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.color};
  overflow: hidden;
  ${props => props.theme.breakpoints.up('sm')} {
    max-height: 400px;
  }
  ${props => props.theme.breakpoints.down('xs')} {
    max-height: 800px;
  }
`;
const InnerFrame = styled.div`
  width: 100%;
  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: row;
    max-height: 100%;
    display: flex;
  }
  ${props => props.theme.breakpoints.down('xs')} {
    display: flex;
    flex-direction: column-reverse;
    max-height: 50%;
  }
`;
const LeftContainer = styled(Grid)`
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.theme.spacing(5)}px;
  display: flex;
`;
const RightContainer = styled(Grid)``;
const StyledTitle = styled.p`
  font-family: ${props => props.theme.typography.h2.fontFamily};
  letter-spacing: ${props => props.theme.typography.h2.letterSpacing};
  ${props => props.theme.breakpoints.up('lg')} {
    font-size: ${props => props.theme.typography.h2.fontSize};
    line-height: ${props => props.theme.typography.h2.lineHeight};
    margin: 0;
  }
  ${props => props.theme.breakpoints.only('md')} {
    font-size: ${props => props.theme.typography.h3.fontSize};
    line-height: ${props => props.theme.typography.h3.lineHeight};
  }
  ${props => props.theme.breakpoints.only('sm')} {
    font-size: ${props => props.theme.typography.h4.fontSize};
    line-height: ${props => props.theme.typography.h4.lineHeight};
  }
  ${props => props.theme.breakpoints.down('xs')} {
    font-size: ${props => props.theme.typography.h3.fontSize};
    line-height: ${props => props.theme.typography.h3.lineHeight};
  }
`;
const StyledSubtitle = styled.p`
  letter-spacing: ${props => props.theme.typography.h6.letterSpacing};
  font-weight: ${props => props.theme.typography.h6.fontWeight};
  ${props => props.theme.breakpoints.up('lg')} {
    font-size: ${props => props.theme.typography.h6.fontSize};
    line-height: ${props => props.theme.typography.h6.lineHeight};
    margin: 0;
  }
  ${props => props.theme.breakpoints.down('md')} {
    font-size: ${props => props.theme.typography.subtitle1.fontSize};
    line-height: ${props => props.theme.typography.subtitle1.lineHeight};
  }
`;

const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
`;
interface HeaderCoverProps {
  coverImageRef?: StorageReference | string;
  color: string;
  title: string;
  subtitle: string;
}

function renderImage(
  title: string,
  subtitle: string,
  coverRef: StorageReference | string | undefined,
  props: HeaderCoverProps
) {
  if (typeof coverRef === 'undefined' || typeof coverRef !== 'string') {
    return null;
  } else {
    return (
      <InnerFrame>
        <LeftContainer item xs={12} md={8}>
          <StyledTitle>{title}</StyledTitle>
          <div style={{ height: 30, width: 1 }}></div>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </LeftContainer>
        <RightContainer item xs={12} md={4}>
          <StyledImage src={coverRef} />
        </RightContainer>
      </InnerFrame>
    );
  }
}
function ClassHeaderCover(props: HeaderCoverProps) {
  const { title, subtitle, coverImageRef, color } = props;

  return (
    <CoverFrame color={color}>
      <MaxWidthCenteredContainer>
        {renderImage(title, subtitle, coverImageRef, props)}
      </MaxWidthCenteredContainer>
    </CoverFrame>
  );
}

export default ClassHeaderCover;
