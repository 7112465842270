import { Button, PropTypes } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const StyledMuiLink = styled(MuiLink)`
  && {
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
`;

interface LinkButtonGenericProps {
  to: string;
  label: string;
  color?: PropTypes.Color;
}

export default function LinkButtonGeneric(props: LinkButtonGenericProps) {
  const { to, label, color } = props;
  const isExternal: boolean = /^https?:\/\//.test(to);

  return (
    <>
      {isExternal ? (
        <StyledMuiLink href={to} target="_blank">
          <Button variant="contained" color={color}>
            {label}
          </Button>
        </StyledMuiLink>
      ) : (
        <StyledLink {...props} color={color}>
          <Button variant="contained" color={color}>
            {label}
          </Button>
        </StyledLink>
      )}
    </>
  );

  //
}
