import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-storage';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/database';

// PROD
/*
const StagingConfig = {
  apiKey: "AIzaSyAmTlKJyC5rE-QWP0yIBROP_i_9vk77XF8",
  authDomain: "gyldendal-teknologi-prod.firebaseapp.com",
  databaseURL: "https://gyldendal-teknologi-prod.firebaseio.com",
  projectId: "gyldendal-teknologi-prod",
  storageBucket: "gyldendal-teknologi-prod.appspot.com",
  messagingSenderId: "519784244147",
  appId: "1:519784244147:web:b1f6b17cd03c5b72"
};
*/

// Staging
/*
const StagingConfig = {
  apiKey: 'AIzaSyDrtZsXWFHR6RdYxxySEFSJVsRaT1HFDZg',
  authDomain: 'gyldendal-flexafvikler.firebaseapp.com',
  databaseURL: 'https://gyldendal-flexafvikler.firebaseio.com',
  projectId: 'gyldendal-flexafvikler',
  storageBucket: 'gyldendal-flexafvikler.appspot.com',
  messagingSenderId: '632922044727',
  appId: '1:632922044727:web:f22ea9fcc707ecb0'
};
*/

// TESTING

const StagingConfig = {
  apiKey: 'AIzaSyCMT25oi6_VqO-P4vyQ6LN7hu0bRTKVmSw',
  authDomain: 'gyldendal-teknologi-testing.firebaseapp.com',
  databaseURL: 'https://gyldendal-teknologi-testing.firebaseio.com',
  projectId: 'gyldendal-teknologi-testing',
  storageBucket: 'gyldendal-teknologi-testing.appspot.com',
  messagingSenderId: '176982644038',
  appId: '1:176982644038:web:845fc46eae616556'
};

export let firebaseConfig: FirebaseFetchedConfig = StagingConfig;

let app: firebase.app.App;

export { app as firebaseapp, firebase };

export let firestore: firebase.firestore.Firestore;

export let googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export let functions: firebase.functions.Functions;

export let perf: firebase.performance.Performance;

interface FirebaseFetchedConfig {
  apiKey: string;
  databaseURL: string;
  storageBucket: string;
  authDomain: string;
  messagingSenderId: string;
  projectId: string;
}

export async function initFirebase() {
  if (process.env.NODE_ENV === 'development') {
    app = firebase.initializeApp(firebaseConfig);

    firestore = firebase.firestore();

    googleAuthProvider = new firebase.auth.GoogleAuthProvider();

    functions = app.functions('europe-west1');

    perf = firebase.performance();
  } else {
    const response = await fetch('/__/firebase/init.json');
    firebaseConfig = (await response.json()) as FirebaseFetchedConfig;
    app = firebase.initializeApp(firebaseConfig);

    firestore = firebase.firestore();

    googleAuthProvider = new firebase.auth.GoogleAuthProvider();

    functions = app.functions('europe-west1');

    perf = firebase.performance();
  }
}
