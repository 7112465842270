import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FooterLogo from '../assets/icons/tf-logo-footer.svg';
import { Content } from './Body';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.footer.color};
  color: ${props =>
    props.theme.palette.getContrastText(props.theme.footer.color)};
  min-height: 400px;
  width: 100%;
`;

const SubText = styled(Typography)`
  && {
    color: ${props => props.theme.extraPalette.grey[600]};
    white-space: pre-line;
    &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  & > div > a {
      &:focus,
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
        color:inherit;
        font-weight: bold;
      }
    }
  }
  }
  //@ts-ignore
`;

const StyledA = styled.a`
  text-decoration: none;
  color: inherit;
`;

const SubSection = styled(Grid)`
  padding-right: 48px;
`;

const GridItemResponsiveNoMargin = styled(Grid)`
  && {
    margin: 0px;
    width: 100%;
    ${props => props.theme.breakpoints.down('md')} {
      margin: -20px;
    }
  }
`;

const Footer = () => {
  const [t] = useTranslation();

  return (
    <StyledFooter>
      <Content>
        <GridItemResponsiveNoMargin container spacing={5}>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <img src={FooterLogo} alt="gyldendal logo" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between" spacing={5}>
              <Grid item xs={12} sm={8} md={4} lg={4}>
                <Typography variant="body2" color="inherit" paragraph>
                  {t('footer.contact.title')}
                </Typography>
                <SubText variant="body2" color="inherit">
                  {t('footer.contact.body_1')}
                  <StyledA
                    href={`mailto:${t('footer.contact.mailto')}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {t('footer.contact.mailto')}
                  </StyledA>
                  <br />
                  <br />
                  {t('footer.contact.body_2')}
                </SubText>
              </Grid>

              <Grid item xs={6} sm="auto">
                &nbsp;
              </Grid>

              <SubSection item xs={12} sm={6} md={3} lg={3}>
                <Typography variant="body2" color="inherit" paragraph>
                  {t('footer.access.title')}
                </Typography>
                <SubText variant="body2" color="inherit">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('footer.access.body_1')
                    }}
                  />

                  <StyledA href={`mailto:${t('footer.access.mailto')}`}>
                    {t('footer.access.mailto')}
                  </StyledA>
                </SubText>
              </SubSection>

              <SubSection item xs={12} sm={6} md={3} lg={3}>
                <Typography variant="body2" color="inherit" paragraph>
                  {t('footer.support.title')}
                </Typography>
                <SubText variant="body2" color="inherit" paragraph>
                  {t('footer.support.body_1')}
                  <StyledA
                    href={`http://${t('footer.support.support_link')}`}
                    target="_blank"
                  >
                    www.{t('footer.support.support_link')}
                  </StyledA>
                </SubText>
                <SubText variant="body2" color="inherit">
                  {t('footer.support.body_gdpr')}
                  <StyledA
                    href={`${t('footer.support.link_gdpr')}`}
                    target="_blank"
                  >
                    <b>{t('footer.support.body_gdpr_link_text')}</b>
                  </StyledA>
                </SubText>
              </SubSection>
            </Grid>
          </Grid>
        </GridItemResponsiveNoMargin>
      </Content>
    </StyledFooter>
  );
};

export default Footer;
