import { getInstitutionsCollectionRef } from '..';
import { FSCourse } from '../../Courses';

export const INSTITUTIONS_COURSES_COLLECTION = 'courses';
export const CUSTOM_EXERCISES = 'customExercises';

export const CourseStatuses = {
  draft: 'draft',
  published: 'published',
  ended: 'ended'
};

export type CourseStatus = keyof typeof CourseStatuses;

export interface FSInstitutionCourse extends FSCourse {
  originalCourse: firebase.firestore.DocumentReference;
  ownerUID: string;
  unicGroups: string[];
  status: CourseStatus;
  firstPublished: firebase.firestore.Timestamp;
  endedTime?: firebase.firestore.Timestamp;
  selectedExerciseListIndex: number;
  selectedTrainingCoursesIndexes: number[];
}

export function getInstitutionsCoursesCollectionRef(institutionId: string) {
  return getInstitutionsCollectionRef()
    .doc(institutionId)
    .collection(INSTITUTIONS_COURSES_COLLECTION);
}

export function getInstitutionsCoursesRef(
  institutionId: string,
  courseId: string
) {
  return getInstitutionsCoursesCollectionRef(institutionId).doc(courseId);
}

export function getCourseCustomExercisesCollectionRef(
  institutionId: string,
  courseId: string
) {
  return getInstitutionsCoursesCollectionRef(institutionId)
    .doc(courseId)
    .collection(CUSTOM_EXERCISES);
}
