import React from 'react';
import Body, { Content } from '../../components/Body';
import Header from '../../components/Header/Header';
import MainMenu, {
  RedButton
} from '../../components/Header/components/MainMenu';
import MainActionMenu from '../../components/ActionMenu/MainActionMenu';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Button, Hidden, Box } from '@material-ui/core';
import Illustration from '../../assets/nolicense/pcthumbsup.png';
import styled from 'styled-components';
import { StyledMuiLink } from '../../components/LinkButtonGeneric';

const ParagraphImage = styled.img`
  && {
    width: 100%;
    max-width: 210px;
  }
`;

const SectionButton = styled(Button)`
  && {
    min-width: 200px;
  }
`;

const HeadLine = styled(Typography)`
  && {
    white-space: pre-line;
    ${props => props.theme.breakpoints.down('sm')} {
      font-size: ${props => props.theme.typography.h3.fontSize};
    }
  }
`;

const WithBreaks = styled(Typography)`
  white-space: pre-line;
`;

export function NoAccess() {
  const [t] = useTranslation();

  return (
    <>
      <Header>
        <MainMenu
          drawerTitle={t('drawer_titles.login')}
          actionMenu={<MainActionMenu />}
          items={[]}
        />
      </Header>
      <Body>
        <Content>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={9}>
              <HeadLine
                variant="h2"
                paragraph
                color="secondary"
                style={{ lineHeight: 'normal' }}
              >
                {t('no_access.title')}
              </HeadLine>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={3}>
                <ParagraphImage src={Illustration} alt="" />
              </Grid>
            </Hidden>
          </Grid>

          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} sm={8} md={6}>
              <WithBreaks variant="body1">
                {t('no_access.test_login_text')}
              </WithBreaks>
            </Grid>
          </Grid>

          <Box height={36} />

          <Grid container direction="row" spacing={3}>
            <Grid item>
              <SectionButton href="/" variant="contained">
                Til forsiden
              </SectionButton>
            </Grid>
            <Grid item>
              <StyledMuiLink
                href={t('action_menu.main.order_login_link')}
                target="_blank"
              >
                <RedButton variant="outlined">
                  {t('no_access.test_login_button')}
                </RedButton>
              </StyledMuiLink>
            </Grid>
          </Grid>
        </Content>
      </Body>
    </>
  );
}
