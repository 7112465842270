import React from 'react';
import Body, { Content } from '../../components/Body';
import { Typography, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import Header from '../../components/Header/Header';
import MainMenu from '../../components/Header/components/MainMenu';
import MainActionMenu from '../../components/ActionMenu/MainActionMenu';
import IllustrationImg from '../../assets/illustrations/challenge.svg';

export default function _404(props: RouteComponentProps) {
  return (
    <>
      <Header>
        <MainMenu actionMenu={<MainActionMenu />} />
      </Header>
      <Body>
        <Content>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Typography color="primary" variant="h3">
                404
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">{window.location.href}</Typography>
              <Typography variant="body1">
                Findes desværre ikke, eller du har ikke adgang.
              </Typography>
            </Grid>
            <Grid item>
              <img src={IllustrationImg} alt="" />
            </Grid>
          </Grid>
        </Content>
      </Body>
    </>
  );
}
