import React from 'react';
import {
  Divider,
  MenuItem,
  ListItemText,
  Typography,
  ListItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ADMIN_INSTITUTIONS_PATH,
  ADMIN_USER_PRESENCE_PATH
} from '../../AppRouter';
import { ActionMenuItemText } from '../../../components/ActionMenu/ActionMenu';
import styled from 'styled-components';
import { useCurrentUser } from '../../../components/Auth/AuthProvider';

const LinkNoDec = styled(Link)`
  text-decoration: none;
`;

export const EditorAdminActionLinks = () => {
  const user = useCurrentUser();

  return (
    <>
      <ListItem dense>
        <Typography variant="h6" color="secondary">
          Admin:
        </Typography>
      </ListItem>
      {user.isPerm('editor') && (
        <MenuItem dense>
          <LinkNoDec to={'/editor-admin'}>
            <ListItemText>
              <ActionMenuItemText>Redaktion</ActionMenuItemText>
            </ListItemText>
          </LinkNoDec>
        </MenuItem>
      )}
      {user.isPerm('admin') && (
        <>
          <MenuItem dense>
            <LinkNoDec to={'/editor-admin/users'}>
              <ListItemText>
                <ActionMenuItemText>Brugere</ActionMenuItemText>
              </ListItemText>
            </LinkNoDec>
          </MenuItem>
          <MenuItem dense>
            <LinkNoDec to={ADMIN_INSTITUTIONS_PATH}>
              <ListItemText>
                <ActionMenuItemText>Institutioner</ActionMenuItemText>
              </ListItemText>
            </LinkNoDec>
          </MenuItem>
          <MenuItem dense>
            <LinkNoDec to={ADMIN_USER_PRESENCE_PATH}>
              <ListItemText>
                <ActionMenuItemText>Brugere pr. måned</ActionMenuItemText>
              </ListItemText>
            </LinkNoDec>
          </MenuItem>
          <Divider variant="inset" />
        </>
      )}
    </>
  );
};
