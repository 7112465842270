import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

const AppBarContainer = styled.div`
  height: 64px;
`;

const Toolbar = styled.div`
  height: 64px;
  display: flex;
  background-color: white;
`;
export interface HeaderProps extends RouteComponentProps {
  children?: ReactNode;
}

const Header = withRouter(function Header(props: HeaderProps) {
  return (
    <AppBarContainer>
      <AppBar position={'fixed'} color="default">
        <Toolbar>
          <Grid container spacing={0} justify="space-between">
            {props.children}
          </Grid>
        </Toolbar>
      </AppBar>
    </AppBarContainer>
  );
});

export default Header;
