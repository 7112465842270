import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ContentHeader = styled(({ marginBottom, ...otherProps }) => (
  <Typography color="secondary" variant="h4" {...otherProps} />
))<{ marginBottom: number }>`
  && {
    font-family: Montserrat;
    letter-spacing: normal;
    margin-bottom: ${props => props.theme.spacing(props.marginBottom)}px;
  }
`;

export const ContentDescription = styled(({ ...otherProps }) => (
  <Typography paragraph variant="body1" {...otherProps} />
))<{}>`
  && {
    letter-spacing: normal;
    white-space: pre-line;
    line-height: 1.74;
    letter-spacing: 0.5px;
  }
`;
