import { Fab, withWidth, Hidden, Grid, Button } from '@material-ui/core';
import { WithWidthProps } from '@material-ui/core/withWidth';
import i18next from 'i18next';
import { isNull } from 'lodash';
import { reverse } from 'named-urls';
import { default as React, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LinkProps,
  matchPath,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import styled from 'styled-components';
import { LOGIN_PATH_UNIC, RoutePaths } from '../../../routes/AppRouter';
import { useCurrentUser, useIsSignedIn, User } from '../../Auth/AuthProvider';
import { GoBackButton, GoBackButtonProps } from '../../GoBackButton';
import TFLogo from '../../TFLogo';
import { buttonSize, StyledStorageMedia } from './DesignPhaseMenu';
import DrawerMenu from './DrawerMenu';
import { StorageReference } from '../../../firestore/collections/Storage';
import { MenuIcon } from '../../../icons/Icons';
import { StyledLink, StyledMuiLink } from '../../LinkButtonGeneric';

const AppMenuListItem = styled(({ isFocused, ...otherProps }) => (
  <Link {...otherProps} />
))<{ isFocused: boolean }>`
  display: flex;
  height: inherit;
  align-items: center;
  padding-top: 3px;
  border-bottom: ${props =>
    props.isFocused
      ? `3px solid ${props.theme.palette.secondary.main}`
      : `3px solid transparent`};
  font-weight: 600;
  font-size: ${props => props.theme.typography.button.fontSize};
  text-decoration: none !important;
  color: ${props =>
    props.isFocused
      ? props.theme.palette.secondary.main
      : props => props.theme.palette.text.primary} !important;
  :hover {
    background-color: ${props => props.theme.extraPalette.grey[200]};
  }
`;

interface AppMenuListItemLinkProps extends RouteComponentProps, LinkProps {}

const AppMenuListItemLink = withRouter((props: AppMenuListItemLinkProps) => {
  const focused = !isNull(
    matchPath(props.location.pathname, {
      path: props.to as string,
      exact: false,
      strict: false
    })
  );
  return (
    <AppMenuListItem isFocused={focused} to={props.to}>
      {props.children}
    </AppMenuListItem>
  );
});

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2em;
  height: 100%;
`;
const LogoLink = styled(Link)`
  text-decoration: none !important;
`;

const BurgerButton = styled(Fab)`
  color: ${props => props.theme.extraPalette.red[500]} !important;
  background-color: transparent !important;
  box-shadow: none !important;
  align-self: center;
`;

export const getDefaultMainMenu = (
  t: i18next.TFunction
): MainMenuLinkItem[] => [
  {
    location: reverse(RoutePaths.courses.templates.overview),
    title: t('paths_names.courses/templates'),
    showForUser: user => {
      return user ? user.isPerm('teacher') : true;
    }
  },
  {
    location: reverse(RoutePaths.courses.my.overview),
    title: t('paths_names.courses/my'),
    showForUser: user => {
      return user ? user.isPerm('student') : false;
    }
  },
  {
    location: reverse(RoutePaths.trainingCourses.overview),
    title: t('paths_names.training_courses'),
    showForUser: user => {
      return user ? user.isPerm('student') : true;
    }
  },
  {
    location: reverse(RoutePaths.myClasses.overview),
    title: t('paths_names.my_classes'),
    showForUser: user => {
      return user ? user.isRole('teacher') : false;
    }
  },
  {
    location: reverse(RoutePaths.teachersResources),
    title: t('paths_names.teachers_resources'),
    showForUser: user => {
      return user ? !user.isRole('student') : true;
    }
  }
];

export interface MainMenuLinkItem {
  title: string;
  location: string;
  showForUser?: (user: User) => boolean;
}

export const CourseImage = styled.div`
  border-radius: ${buttonSize / 2}px;
  height: ${buttonSize}px;
  width: ${buttonSize}px;
  padding: 0 8px 3px 8px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.3);
  padding: 0 ${props => props.theme.spacing(2)}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px 0 7px 0;
`;

const LeftGridItem = styled(Grid)`
  && {
    align-self: center;
  }
`;
const CenterGridItem = styled(Grid)`
  && {
    text-align: center;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;

    ${props => props.theme.breakpoints.down('xs')} {
      padding-left: 0px;
      padding-right: 0px;
      overflow: hidden;
    }
  }
`;

const MenuContainer = styled(Grid)`
  && {
    padding: 0px;
    margin: 0px;
    height: 100%;
  }
`;

const MenuItem = styled(Grid)`
  && {
    height: inherit !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;

export const BlackButton = styled(({ ...otherProps }) => (
  <Button {...otherProps} classes={{ root: 'root' }}></Button>
))<{
  color?: string;
}>`
  &.root {
    background-color: #000000;
    &:hover {
      background-color: #666666;
    }
  }
`;

export const RedButton = styled(({ ...otherProps }) => (
  <Button {...otherProps} classes={{ root: 'root' }}></Button>
))<{
  color?: string;
}>`
  &.root {
    border: 1px solid #ff0000;
    color: #ff0000;
    background-color: #ffffff;
    &:hover {
      background-color: #eedddd;
    }
  }
`;

/*
&:hover {
      color: ${props =>
        props.hoverColor
          ? props.hoverColor
          : props.theme.extraPalette.grey[700]};
    }
*/

interface MainMenuProps extends RouteComponentProps, WithWidthProps {
  items?: (() => MainMenuLinkItem[]) | MainMenuLinkItem[];
  goBackButton?: GoBackButtonProps;
  goBackMenuItem?: MainMenuLinkItem;
  actionMenu?: JSX.Element;
  drawerTitle?: string;
  image?: StorageReference;
}

const MainMenu = withRouter((props: MainMenuProps) => {
  const [t] = useTranslation();
  const isSignedIn = useIsSignedIn();
  const user = useCurrentUser();
  const [openDrawer, setOpenDrawer] = useState(false);
  const defaultMainMenu = useMemo(() => getDefaultMainMenu(t), [t]);
  //const theme = useTheme();
  //const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  //console.log('isLgUp', isLgUp);

  let headerMenuItems =
    typeof props.items === 'function' ? props.items() : props.items;

  if (!headerMenuItems) {
    headerMenuItems = defaultMainMenu;
  }

  const filteredMenuItems = headerMenuItems.filter(item => {
    if (typeof item.showForUser === 'function') {
      return item.showForUser(user);
    }
    return true;
    /*
    if (isSignedIn && typeof item.showForUser === 'function') {
      console.log("item", item.title);
      
      if (!item.showForUser(user)) {
        return false;
      }
    } else if (!isSignedIn && !!item.showForUser) {
      return false;
    }
    return true;
    */
  });

  let drawerMenuItems = filteredMenuItems;
  if (props.goBackMenuItem) {
    drawerMenuItems = [props.goBackMenuItem].concat(drawerMenuItems);
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ height: '100%' }}
      >
        <LeftGridItem item>
          {props.width === 'sm' || props.width === 'xs' ? (
            <BurgerButton onClick={() => setOpenDrawer(true)}>
              <MenuIcon color="secondary" />
            </BurgerButton>
          ) : props.goBackButton ? (
            <GoBackButton {...props.goBackButton} />
          ) : (
            <LogoContainer>
              <LogoLink to={'/'}>
                <TFLogo />
              </LogoLink>
            </LogoContainer>
          )}
        </LeftGridItem>
        <CenterGridItem item xs>
          <Hidden smDown>
            {!headerMenuItems ? null : (
              <MenuContainer
                container
                direction="row"
                wrap="nowrap"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                {props.image && (
                  <Grid item>
                    <CourseImage>
                      <StyledStorageMedia storageReference={props.image} />
                    </CourseImage>
                  </Grid>
                )}
                {filteredMenuItems
                  ? filteredMenuItems.map((item, i) => {
                      return (
                        <MenuItem item key={i} style={{}}>
                          <AppMenuListItemLink to={item.location}>
                            {item.title}
                          </AppMenuListItemLink>
                        </MenuItem>
                      );
                    })
                  : null}
              </MenuContainer>
            )}
          </Hidden>
        </CenterGridItem>
        <Grid item style={{ textAlign: 'right', alignSelf: 'center' }}>
          <>
            {isSignedIn && props.actionMenu}
            {!isSignedIn && props.location.pathname !== '/login' && (
              <>
                <Hidden smDown>
                  <StyledMuiLink
                    href={t('action_menu.main.order_login_link')}
                    target="_blank"
                  >
                    <RedButton variant="outlined">
                      {t('action_menu.main.order_login')}
                    </RedButton>
                  </StyledMuiLink>
                  <StyledLink
                    color="default"
                    to={LOGIN_PATH_UNIC}
                    style={{ paddingLeft: 14, paddingRight: 16 }}
                  >
                    <BlackButton variant="contained">
                      {t('action_menu.main.log_in')}
                    </BlackButton>
                  </StyledLink>
                </Hidden>

                <Hidden mdUp>
                  <StyledMuiLink
                    href={t('action_menu.main.order_login_link')}
                    target="_blank"
                  >
                    <RedButton variant="outlined" size="small">
                      {t('action_menu.main.order_login_small')}
                    </RedButton>
                  </StyledMuiLink>

                  <StyledLink
                    color="secondary"
                    to={LOGIN_PATH_UNIC}
                    style={{ paddingLeft: 14, paddingRight: 16 }}
                  >
                    <BlackButton variant="contained" size="small">
                      {t('action_menu.main.log_in_small')}
                    </BlackButton>
                  </StyledLink>
                </Hidden>
              </>
            )}
          </>
        </Grid>
      </Grid>

      <DrawerMenu
        type={'main'}
        drawerTitle={props.drawerTitle}
        user={user}
        headerMenuItems={drawerMenuItems}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        goBackButton={props.goBackButton}
      />
    </>
  );
});

export default withWidth()(MainMenu);
