import React from 'react';
import { Redirect, RouteProps, Route } from 'react-router';
import { useCheckAuth, User, useAuth } from './AuthProvider';
import { LOGIN_PATH, NO_LICENSE_PATH } from '../../routes/AppRouter';
import _404 from '../../routes/_404';

interface ProtectedRouteProps extends RouteProps {
  doNotRedirect?: boolean;
  onAuth?: (user: User) => boolean;
}
export default function ProtectedRoute(props: ProtectedRouteProps) {
  useCheckAuth();
  const [currentUser, isLoading] = useAuth();
  if (isLoading) {
    return null;
  }

  if (!currentUser) {
    if (props.doNotRedirect) {
      return null;
    } else {
      return (
        <Redirect
          to={{
            pathname: LOGIN_PATH,
            state: { referrer: props.location }
          }}
        />
      );
    }
  }

  if (currentUser && !currentUser.hasLicense) {
    return (
      <Redirect
        to={{
          pathname: NO_LICENSE_PATH,
          state: { referrer: props.location }
        }}
      />
    );
  }

  if (props.onAuth) {
    if (props.onAuth(currentUser) !== true) {
      if (props.doNotRedirect) {
        return null;
      }
      return <Route component={_404} />;
    }
  }

  return <Route {...props} />;
}
